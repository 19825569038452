import React, { useState, useEffect } from 'react';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import './pagination/style.css';
import axios from 'axios';
const OverviewWithdraw = (props) => {
    document.title = `OverviewWithdraw - ${process.env.REACT_APP_APP_NAME}`
    // console.log("staking",props);

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);


    const onGetAllRecord = async () => {
        if (props.currentAccount) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord",submethod:"withdrawal", fromaddress: props.currentAccount })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data.sta);

                    setstaking(res.data)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onGetAllRecord();
    }, [props])
    const columns = [
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: "82px"
        },
        {
            name: "Hash",
            selector: "hash",
            cell: (data) => <a target="_blank" href={`${process.env.REACT_APP_EXPLORER}tx/${data.fhash}`}>{data.hash}</a>,
            key: "hash",
            width: "200px"
        },
        {
            name: "Total",
            selector: "total",
            key: "total",
            width: 100
        },

        {
            name: "Rate",
            selector: "rate",
            key: "rate",
            width: 100
        },
        {
            name: "Fee($)",
            selector: "fee_dollar",
            key: "fee_dollar",
            width: 100
        },

        {
            name: "Fee(token)",
            selector: "fee_token",
            key: "fee_token",
            width: 100
        },

        {
            name: "Burn(%)",
            selector: "burn_per",
            key: "burn_per",
            width: 100
        },
        {
            name: "Burn($)",
            selector: "total_burn_dollar",
            key: "total_burn_dollar",
            width: 100
        },
        {
            name: "Burn(Token)",
            selector: "total_burn_token",
            key: "total_burn_token",
            width: "160px"
        },
        {
            name: "Recieved",
            selector: "total_receive",
            key: "total_receive",
            cell: (data) => <span>{data.total_receive.toFixed(2)}</span>,
            width: "100px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "250px"
        }

    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div class="col-lg-12">
                <div class="td-sidebar" >
                    <div className="table-responsive text-nowrap tb">
                        <div class="widget widget_add">
                            <h3 class="widget-title style-white text-left stakingliner" >Withdrawal List</h3>

                            <DataTableExtensions {...tableData}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    progressPending={loading}
                                    dense
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </>
    );
}

export default OverviewWithdraw;