import React from 'react';

function Features() {
    return (
        <>
             <section className="pages_content" id="benefits">
                <div className="featured-area bg-cover bg-overlay-2 text-center pd-top-95 pd-bottom-70"
                    style={{ backgroundImage: "url(assets/img/feature/bg.jpg)" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 align-self-center">
                                <div className="section-title style-white text-center">
                                    <h5 className="sub-title">Why OXYO2</h5>
                                    <h2 className="title">Competitive Benefit</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6">
                                <div className="single-feature-inner default-card">
                                    <div className="default-card-inner"></div>
                                    <div className="thumb">
                                        <img src="assets/img/feature/1.png" alt="img" />
                                    </div>
                                    <div className="details" style={{marginTop:"-56px"}}>
                                        <h4>Secure Transaction</h4>
                                        <p>We have the safest network structure to provide the latest and secure method during the transaction. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="single-feature-inner default-card default-card-active">
                                    <div className="default-card-inner"></div>
                                    <div className="thumb">
                                        <img src="assets/img/feature/2.png" alt="img" />
                                    </div>
                                    <div className="details" style={{marginTop:"-56px"}}>
                                        <h4>Powerful Ecosystem</h4>
                                        <p>Our Ecosystem includes the products & Solutions to increase the functionality of oxyO2. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="single-feature-inner default-card">
                                    <div className="default-card-inner"></div>
                                    <div className="thumb">
                                        <img src="assets/img/feature/3.png" alt="img" />
                                    </div>
                                    <div className="details" style={{marginTop:"-56px"}}>
                                        <h4>Easy & Convenient System</h4>
                                        <p>Our System is built with very easy transfer mode so it’s convenient for everyone.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="single-feature-inner default-card">
                                    <div className="default-card-inner"></div>
                                    <div className="thumb">
                                        <img src="assets/img/feature/4.png" alt="img" />
                                    </div>
                                    <div className="details" style={{marginTop:"-56px"}}>
                                        <h4>Instant Transaction</h4>
                                        <p>oxyO2 stands for instant payment system in which payment will be done in a blink. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="single-feature-inner default-card">
                                    <div className="default-card-inner"></div>
                                    <div className="thumb">
                                        <img src="assets/img/feature/5.png" alt="img" />
                                    </div>
                                    <div className="details" style={{marginTop:"-56px"}}>
                                        <h4>Smart Web</h4>
                                        <p>Using the latest version to ensure the productivity of our service. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Features;