import React, { useContext } from 'react';
import { Oxyo2 } from '../Oxyo2';

const Adrbox = (props) => {
    const { account, copyaddress, userData } = useContext(Oxyo2);

    return (
        <>
            <div className="col-lg-6 col-12">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="td-sidebar" id="dtBasicExample" style={{ textAlign: "left" }}>
                            <div className="widget widget_add">
                                <div className="row">
                                    <h4 style={{ color: "white" }}><br />Address</h4>
                                    <h5 title="click to copy address" className="text-truncate" onClick={() => copyaddress(props.noacc ? props.Address : account)} style={{ color: "white" }}> <span>{props.noacc ? props.Address : account}</span> <i className="far fa-copy" ></i><br /><br /></h5>
                                </div>
                                <div className="row">
                                    <h4 style={{ color: "white" }} > Sponsor Wallet</h4>
                                    {!userData.reff_address ?
                                        <><h5 style={{ color: "white" }} className='d-flex text-truncate'>No Referral</h5></>
                                        : <h5 style={{ color: "white" }} title="click to copy address" onClick={() => copyaddress(userData.reff_address)} className='d-flex text-truncate'><span>{userData.reff_address}</span><br />&nbsp;<i className="pr-1 far fa-copy" ></i></h5>
                                    }
                                </div>
                                {userData.restake_date ?
                                    <div className="row text-center">
                                        <h4 style={{ color: "white" }} >4x Date</h4>
                                        <h5 style={{ color: "white" }} className=' text-center text-truncate'><span>{userData.restake_date}</span><br /></h5>
                                    </div> :
                                    ''
                                }
                                <button title="click to copy referral link" className="btn btn-base mt-2" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}/${account}`)}><i className="fa-solid fa-share-nodes"></i> Referral Link</button>
                                <a href="/#staking" className="btn btn-base mt-2" style={{ marginLeft: "10px" }}>New Stake</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Adrbox;