import axios from "axios";
import React, { useState, useEffect } from "react";

function SocialMedia() {
    const [social_media, setsocial_media] = useState('');

    const onGet = async (e) => {
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "social_media", submethod: "get" })
            .then(res => {
                setsocial_media(res.data)
            })
    }
    useEffect(() => {
        onGet();
    }, [])

    return (
        <>
            {/* <div className="fact-area bg-cover bg-overlay-1 text-center pd-top-100 pd-bottom-100" style={{ backgroundImage: "url(assets/img/home-2/fact/bg.jpg)" }}>
                <div className="container"> */}
            <div className="row justify-content-center" style={{ marginTop: "130px" }}>
                <div className="col-lg-7">
                    <div className="section-title style-white text-center">
                        <h2 className="title">Social Sites</h2>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                {social_media.length && social_media.map((item, key) => {
                    if (item.url === '') {
                        return ('')
                    } else {
                        return (
                            <div className="col-md-2 col-sm-6">
                                <a href={`https://${item.url}`} target="_blank" rel="noopener noreferrer">
                                    <div className="single-fact-inner style-two">
                                        <div className="thumb" style={{ fontSize: "45px", color: "#fff" }}>
                                            <i key={item.name} className={`fab fa-${item.name}`}></i>
                                        </div>
                                        <div className="details">
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    }
                })}
            </div>
            {/* </div>
            </div> */}

        </>
    )
}


export default SocialMedia;