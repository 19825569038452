import React, { useState, useEffect, useContext } from "react";
import Footer from "./Footer";
import Header2 from "./Header2";
import Dashmenu from "./DashboardMenu";
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "./pagination/style.css";
import axios from "axios";
import Adrbox from "./DashboardAdrBox";
import Blcbox from "./DashboardBlcBox";
import { Oxyo2 } from "../Oxyo2";
import { columnsData, tableBodyData } from "./Data";
import { Link } from "react-router-dom";

const SwapList = (props) => {
    const { account, formatAddress, setting } = useContext(Oxyo2);

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);

    const onGetAllRecord = async () => {
        if (account) {
            setLoading(true);
            await axios
                .post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap2", submethod: "swapaddress", address: account, pageSize: 1000 })
                .then((res) => {
                    setstaking(res.data.data);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        onGetAllRecord();
    }, [account]);

    const newTableData = {
        columnsData,
        tableBodyData,
    };

    const columns = [
        {
            name: "#",
            selector: "id",
            key: "id",
            width: "50px",
        },
        {
            name: "Hash",
            selector: "hash",
            cell: (data) => (
                <a target="_blank" href={`${process.env.REACT_APP_EXPLORER}tx/${data["hash"]}`}>
                    {formatAddress(data["hash"])}
                </a>
            ),
            key: "hash",
            width: "180px",
        },

        {
            name: "Amount",
            selector: "rate",
            cell: (data) => (
                <>
                    <span>{data["amount"]}&nbsp;{process.env.REACT_APP_TICKER}</span>
                </>
            ),
            key: "rate",
            width: "140px",
        },
        {
            name: "Datetime",
            selector: "datetime",
            cell: (data) => (
                <>
                    <span>{data["datetime"]}</span>
                </>
            ),
            key: "datetime",
            width: "200px",
        },
        {
            name: "Status",
            selector: "status",
            cell: (data) => (
                <>
                    <span>{data["status"] === 1 || data["status"] === "1" ? "Swap" : "Whitelist"}</span>
                </>
            ),
            key: "datetime",
            width: "160px",
        },
    ];
    const data = [...staking];
    const tableData = {
        columns,
        data,
    };
    return (
        <>
            <Header2 />

            <div class="blog-area bg-blue pd-top-100 pd-bottom-100 dashboard_box_image">
                <div class="container" style={{ marginTop: "20px" }}>
                    <div class="fact-area bg-cover bg-overlay-2 text-center pd-bottom-70">
                        <div class="container">
                            <div class="row mb-5">
                                <Adrbox />
                                <Blcbox />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="td-sidebar">
                                <Dashmenu />
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="td-sidebar">
                                <div className="table-responsive text-nowrap tb">
                                    <div class="widget widget_add">
                                        <h3 class="widget-title style-white text-left">
                                            <div className="d-flex justify-content-between align-items-center">
                                                Swap List
                                                {setting ? (
                                                    setting.swap_status === "false" || setting.swap_status === false ? (
                                                        <a href="/#swaps_page" className="btn btn-base" style={{ padding: "0px 55px" }}>
                                                            Swap
                                                        </a>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </h3>
                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={true}
                                                pagination
                                                progressPending={loading}
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SwapList;
