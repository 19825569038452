import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import './pagination/style.css';
import axios from 'axios';
import "./styles.css";

const AcOvReferralReward = (props) => {
    document.title = `Referral Reward - ${process.env.REACT_APP_APP_NAME}`

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);
    // console.log("im from dashboard ", props.currentAccount);

    const onGetAllRecord = async () => {
        if (props.currentAccount) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "level", fromaddress: props.currentAccount })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data.sta);

                    setstaking(res.data)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onGetAllRecord();

    }, [props])
    const columns = [
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: 100
        },
        {
            name: "flushed",
            selector: "flushed",
            cell: (data) => <strong className='text-danger text-uppercase' >{data.flushed}</strong>,
            key: "flushed",
            width: "100px"
        },
        {
            name: "F.Add",
            selector: "fromaddress",
            key: "fromaddress",
            width: 100
        },
        {
            name: "Reward($)",
            selector: "income",
            key: "income",
            width: 100
        },
        {
            name: "Level ",
            selector: "income_level",
            key: "income_level",
            width: 100
        },
        // {
        //   name: "Round",
        //   selector: "round",
        //   key: "round",
        //   width:100
        // },
        {
            name: "Reward(%)",
            selector: "income_per",
            key: "income_per",
            width: 100
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: 100
        }

    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>


            <div class="col-lg-12">
                <div class="td-sidebar" >
                    <div className="table-responsive text-nowrap tb">
                        <div class="widget widget_add">
                            <h3 class="widget-title style-white text-left stakingliner" >Referral Reward</h3>

                            <DataTableExtensions {...tableData}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination
                                    progressPending={loading}
                                    highlightOnHover
                                    dense
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </>
    );
}

export default AcOvReferralReward;



