import React, { useState, useEffect, useContext } from 'react';
import Footer from './Footer';
import Header2 from './Header2';
import Dashmenu from './DashboardMenu'
import './styles.css';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import parse from 'html-react-parser';
import { Oxyo2 } from '../Oxyo2';
var dateTime = require('node-datetime');
const Withdrawal = (props) => {
    const { account, setting, userData, toastError, toastSuccess, UDashD, RATE,LIVERATE,library, getUserData } = useContext(Oxyo2)

    document.title = `Withdraw - ${process.env.REACT_APP_APP_NAME}`
    const [restaking, setrestaking] = useState();
    const [Balance, setBalance] = useState();
    const [withdrawdata, setwithdrawdata] = useState([]);
    const [stakedata, setstakedata] = useState();

    const [withAmt, setwithAmt] = useState(0);
    const [withRcv, setwithRcv] = useState(0);
    // const [withRcvD, setwithRcvD] = useState(0);
    const [feeD, setfeeD] = useState(0);
    const [feeT, setfeeT] = useState(0);
    const [burnT, setburnT] = useState(0);
    const [burnD, setburnD] = useState(0);
    const [thash, setthash] = useState(0);
    const [tstart, settstart] = useState(false);
    const [tcomp, settcomp] = useState('');
    const [ppliverate, setppliverate] = useState(0);
    const [loading, setLoading] = useState(false);


    const onenteramt = (e) => {
        // console.log("Token rate", ppliverate);
        var converttoliverate = (((process.env.REACT_APP_WITHDRAW_FEE * withAmt) / 100) / ppliverate)
        setwithRcv(withAmt / ppliverate - converttoliverate);
        // setwithRcvD(withAmt * ppliverate - ((process.env.REACT_APP_WITHDRAW_FEE * withAmt) / 100))
        setfeeD((process.env.REACT_APP_WITHDRAW_FEE * withAmt) / 100)
        setfeeT(((process.env.REACT_APP_WITHDRAW_FEE * withAmt) / 100) / ppliverate)
        
        setburnT(((process.env.REACT_APP_BURN_PER * withAmt) / 100) / ppliverate)
        setburnD((process.env.REACT_APP_BURN_PER * withAmt) / 100)
        // console.log("withAmtburn", burnD);
    }
    // console.log("baolance  : ", Balance)
    const onWithdraw = async () => {
        
        if (!restaking) {
            var contract = new library.eth.Contract(JSON.parse(setting.abi), setting.contract_address);
            var with_contract = new library.eth.Contract(JSON.parse(setting.withdraw_bnb_abi), setting.withdraw_bnb);
            var checkblock = await contract.methods.checkBlocked(account).call();
            if(checkblock){
                toastError(`Your address is in Blacklist!`);
                return 
            }
            if(userData.blocked === true){ 
                toastError('Your account is blocked') 
                return 
            }
            if (setting.minimum_withdraw <= withAmt && withRcv <= Number(5000)) {
                if (Balance >= withAmt) {
                    // if (setting.max_token_withdraw < (feeT + withRcv)) {
                    //     toastError(`You can only withdraw ${setting.max_token_withdraw} ${setting.ticker}`);
                    //     settstart(false)
                    //     return
                    // }
                    settstart(true)                    

                    var dt = dateTime.create();
                    var date_formatted = dt.format('Y-m-d H:M:S');
                    
                    async function mainwi(transactionHash){
                        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "withdraw", address: account, total: withAmt, rate: ppliverate, fee_dollar: feeD, fee_token: feeT, burn_per: process.env.REACT_APP_BURN_PER, total_burn_dollar: burnD, total_burn_token: burnT, total_receive: withRcv, datetime: date_formatted, bnb_hash: transactionHash,isAdmin:false})
                            .then(res => {
                                // console.log("reswith", res);
                                settstart(false)
                                if (res.data.error) {
                                    toastError(res.data.error);
                                    return ''
                                }
                                // setthash(res.data.hash)
                                getUserData()
                                toastSuccess('Request Accepted!');
                                onGetPwith()
                            })
                    }
                    if(setting.take_withdraw_bnb){
                        // let contractAP1 = new library.eth.Contract(JSON.parse(setting.busd_token_abi), setting.busd_token_address);
                        // let checkALW = await contractAP1.methods.allowance(library.utils.toChecksumAddress(account), library.utils.toChecksumAddress(setting.withdraw_bnb)).call();
                        // if (Number(checkALW) === 0){
                        //     await contractAP1.methods.approve(setting.withdraw_bnb, library.utils.toWei("100000", "ether")).send({ from: account }).then(async (res) => {
                        //         if (res.transactionHash) {
                        //             toastSuccess("Approval done.");
                        //         } else {
                        //             toastError("Approval Canceled!");
                        //         }
                        //     });
                        // }
                        
                        await with_contract.methods.buyToken(["0x55d398326f99059fF775485246999027B3197955","0x53940D46a35162255511ff7cade811891d49533c"]).send({ from: account ,value: library.utils.toWei(setting.take_withdraw_bnb_amt, 'ether')},
                            async function (err, transactionId) {
                                if (err) {
                                    // toastDismiss();
                                    // sethidesbtn(false)
                                    toastError('Transaction declined!')
                                    return
                                }
    
                            }).then(async res => {
                                await mainwi(res.transactionHash)
                            })
                    }else{
                        await mainwi(null)
                    }
                } else {
                    toastError('Insufficient Wallet Balance!');
                }
            } else {
                toastError('Withdraw minimum $15 & maximum 5000 KRPZA');
            }
        } else {

            toastError('Restake to active your wallet');
        }
    }
    const html = setting.withdrawal_message ? setting.withdrawal_message : '<span></span>';

    const onGetPwith = async (e) => {
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "penwithdrawal", fromaddress: account })
            .then(res => {
                setwithdrawdata(res.data)
            })
    }
    // const onGetstake = async (e) => {
    //     axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getStack",fromaddress: account })
    //         .then(res => {
    //             console.log("DATA",res.data.length,res.data[0].token_rate);
    //             res.data.length != 0 ? setstakedata(res.data[0].token_rate) : setstakedata(RATE);
    //         })
    // }
    
    useEffect(() => {
    //    setppliverate(RATE);
        onGetPwith()
        // onGetstake()
    }, [account])
    
    useEffect(() => {
        // // if(userData.onetimerestake == true && userData.onetimerestakerate >Number(0.06))
        // // {
        //     setppliverate(userData.onetimerestakerate);
        // }else{
            setppliverate(LIVERATE);
        // }
    }, [LIVERATE,userData])


    useEffect(() => {
        setBalance((userData.wallet_balance - userData.total_withdrawal) - UDashD.pending_withdraw);
        if (userData) {
            var d = new Date(userData.restake_date);
            d.setDate(d.getDate() + 3);

            var td = new Date();
            var yr = td.getFullYear();
            var mn = td.getMonth() + 1;
            var dy = td.getDate();

            var yr1 = d.getFullYear();
            var mn1 = d.getMonth() + 1;
            var dy1 = d.getDate();

            var cdt = mn + "-" + dy + "-" + yr;

            var cdt2 = mn1 + "-" + dy1 + "-" + yr1;

            if (userData.restaking) {
                if(userData.onetimewithdrawal){
                    setrestaking(false);
                }
                else{
                    if (new Date(cdt2) >= new Date(cdt)) {
                        setrestaking(false);
                    } else {
                        setrestaking(true);
                    }
                }
            } else {
                setrestaking(userData.restaking);
            }
        }

    }, [userData, UDashD])
    const columns = [
        {
            name: "Total",
            selector: "total",
            key: "total",
            width: 100
        },

        {
            name: "Rate",
            selector: "rate",
            key: "rate",
            width: 100
        },
        {
            name: "Fee($)",
            selector: "fee_dollar",
            key: "fee_dollar",
            width: 100
        },

        {
            name: "Fee(token)",
            selector: "fee_token",
            key: "fee_token",
            width: 100
        },

        {
            name: "Burn(%)",
            selector: "burn_per",
            key: "burn_per",
            width: 100
        },
        {
            name: "Burn($)",
            selector: "total_burn_dollar",
            key: "total_burn_dollar",
            width: 100
        },
        {
            name: "Burn(Token)",
            selector: "total_burn_token",
            key: "total_burn_token",
            width: "160px"
        },
        {
            name: "Recieved",
            selector: "total_receive",
            key: "total_receive",
            cell: (data) => <span>{Number(data.total_receive).toFixed(2)}</span>,
            width: "100px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "250px"
        }

    ];
    const data = [
        ...withdrawdata
    ];
    // const data1 = [
    //     ...stakedata
    // ]
    const tableData = {
        columns,
        data
    };
    return (
        <>
            <Header2 />
            <div class="blog-area bg-blue pd-top-100 dashboard_box_image">
                <div class="container" style={{ marginTop: "20px" }}>
                    <div class="fact-area bg-cover bg-overlay-2 text-center">
                        <div class="container">
                            <div class="row mb-5">
                                <Adrbox />
                                <Blcbox ppliverate/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="td-sidebar">
                                <Dashmenu />
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="row justify-content-center mb-4">
                                {withdrawdata.length ?
                                    <div class="col-lg-12">
                                        <div class="td-sidebar" >
                                            <div className="table-responsive text-nowrap tb">
                                                <div class="widget widget_add">
                                                    <h3 class="widget-title style-white text-left stakingliner" >Pending Withdrawal</h3>
                                                    <DataTableExtensions {...tableData}>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            defaultSortField="id"
                                                            defaultSortAsc={true}
                                                            pagination
                                                            progressPending={loading}
                                                            highlightOnHover
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}
                            </div>
                            <div class="row justify-content-center mb-4">
                                <div class="col-lg-12">
                                    <div class="td-sidebar" id="dtBasicExample">
                                        <div class="widget widget_catagory">
                                            <div class="contact-inner" style={{ backgroundColor: "#2f2f2f", padding: "0px" }}>
                                                {!setting.withdrawal_status ?
                                                    <div class="col-lg-12">
                                                        <h4 class="subtitle mb-3">
                                                            <div className='d-flex justify-content-between'>
                                                                <h4>Buy Oxyo2</h4>
                                                                <h4>Minimum: $ {setting.minimum_withdraw}</h4>
                                                            </div>
                                                        </h4>
                                                        <div class="single-input-inner style-border mb-1 row">
                                                            <div className="col-md-3" ><span class="input-group-text" style={{ fontSize: "20px" }}><p class="text-white">WALLET BALANCE</p></span></div>
                                                            <div className="col-md-8 text-white"> <span value="100 USD" type="text" style={{ fontSize: "20px" }}>$ {Balance}</span> </div>
                                                        </div>
                                                        <div class="single-input-inner style-border mb-1 row">
                                                            <div className="col-md-3" ><span class="input-group-text" style={{ fontSize: "20px" }}><p class="text-white">Live Rate</p></span></div>
                                                            <div className="col-md-8 text-white"> <span value="100 USD" type="text" style={{ fontSize: "20px" }}>$ {ppliverate}</span> </div>
                                                        </div>
                                                        <div class="single-input-inner style-border mb-1">
                                                            <span class="input-group-text">ENTER AMOUNT</span>
                                                            <span class="icon"><i class="fa fa-user"></i></span>
                                                            <input placeholder="Enter Amount" type="text" onKeyUp={(e) => onenteramt()} onChange={(e) => setwithAmt(e.target.value)} />
                                                        </div>
                                                        <div class="single-input-inner style-border mb-1 mt-3 row">
                                                            <div className="col-md-3"><span class="input-group-text" style={{ fontSize: "20px" }}><p class="text-white">Buy Oxyo2 Fee</p></span></div>

                                                            <div className="col-md-8 text-white"> <span value="100 USD" type="text" style={{ fontSize: "20px" }}>$ {feeD} ({process.env.REACT_APP_WITHDRAW_FEE} %)</span> </div>
                                                        </div>
                                                        <div class="single-input-inner style-border mb-1 row">
                                                            <div className="col-md-3"><span class="input-group-text" style={{ fontSize: "20px" }}><p class="text-white">Burn Fee $</p></span></div>

                                                            <div className="col-md-8 text-white"> <span value="100 USD" type="text" style={{ fontSize: "20px" }}>$ {burnD} ({process.env.REACT_APP_BURN_PER} %)</span> </div>
                                                        </div>
                                                        <div class="single-input-inner style-border mb-1 row">
                                                            <div className="col-md-3"><span class="input-group-text" style={{ fontSize: "20px" }}><p class="text-white">Burn Fee Token</p></span></div>

                                                            <div className="col-md-8 text-white"> <span value="100 USD" type="text" style={{ fontSize: "20px" }}>{burnT} {setting.ticker} ($ {ppliverate})</span> </div>
                                                        </div>
                                                        <div class="single-input-inner style-border mb-1 row">

                                                            <div className="col-md-3"><span class="input-group-text" style={{ fontSize: "20px" }}><p class="text-white">Total Receive</p></span></div>

                                                            <div className="col-md-8 text-white"> <span value="100 USD" type="text" style={{ fontSize: "20px" }}>{withRcv} {setting.ticker}</span> </div>

                                                        </div>
                                                        {thash ?
                                                            <div class="single-input-inner style-border mb-1 row text-center">
                                                                <span class="input-group-text align-center justify-content-center" >{tcomp}</span>
                                                                {tstart ?
                                                                    <div className=' text-center'>
                                                                        <img src="assets/img/loader.gif" className='img-fluid ' style={{ maxWidth: '100px' }} alt="" />
                                                                    </div>
                                                                    : ''}
                                                                <span class="input-group-text align-center justify-content-center text-break" ><p class="text-white">Transaction Hash: </p></span>
                                                                <span class="input-group-text align-center justify-content-center text-break text-truncate" ><p class="text-white"><a href={`https://bscscan.com/tx/${thash}`}> {thash}</a> </p></span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {tstart ?
                                                            '' :
                                                            <>
                                                                {feeD && burnT && burnD && withRcv ?
                                                                    <button class="btn btn-base mt-3" onClick={() => onWithdraw()}>Withdraw <i class="fas fa-arrow-circle-right ms-2"></i></button>
                                                                    : ''}
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    <div class="col-lg-12">
                                                        <h4 class="subtitle mb-3">Withdraw</h4>
                                                        <div class="single-input-inner style-border mb-1 row">
                                                            <div className="col-md-12" >
                                                                <span class="input-group-text" style={{ fontSize: "20px" }}>{parse(html)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* weekly */}
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Withdrawal;