
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";


const POLLING_INTERVAL = 12000;
export const injected = new InjectedConnector({
    supportedChainIds: [56]
})

const RPC_URLS = {
    56: "https://bsc-dataseed.binance.org/"
};


export const RPC = "https://bsc-dataseed.binance.org/";

export const walletconnect = new WalletConnectConnector({
    rpc: RPC_URLS,
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
    supportedChainIds: [56],
});

export const chainData = [
    {
        chainId: '0x38',
        chainName: 'BSC Mainnet',
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com'],
        nativeCurrency:
        {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
    }
]

// export const chainData = [
//     {
//         chainId: '0x61',
//         chainName: 'BSC Testnet',
//         rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
//         blockExplorerUrls: ['https://testnet.bscscan.com'],
//         nativeCurrency:
//         {
//             name: 'BNB',
//             symbol: 'BNB',
//             decimals: 18
//         },
//     }
// ]
