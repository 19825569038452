import React, { useContext } from 'react';
import { Oxyo2 } from '../Oxyo2';
function Statestics() {
    const { DashD, kFormatter } = useContext(Oxyo2);


    return (
        <>
            <div className="fact-area bg-cover bg-overlay-1 text-center pd-top-100 pd-bottom-100"
                style={{ backgroundImage: "url(assets/img/home-2/fact/bg.jpg)" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="section-title style-white text-center">
                                <h5 className="sub-title">Statistics</h5>
                                <h2 className="title">Oxyo2 Overview</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-6">
                            <div className="single-fact-inner style-two">
                                <div className="thumb">
                                    <img src="assets/img/fact/2.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h3>{DashD.total_staker ? kFormatter(DashD.total_staker) : ' 0'}</h3>
                                    <p>Total Members</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-fact-inner style-two">
                                <div className="thumb">
                                    <img src="assets/img/fact/3.png" alt="img" />
                                </div>
                                <div className="details">
                                    {/* <h3>{DashD.total_token_transactions ? DashD.total_token_transactions : 0}+</h3>
                                    <p>Total Transactions</p> */}
                                    <h3>{DashD.total_burn_token ? kFormatter(DashD.total_burn_token) : ' 0'} {process.env.REACT_APP_TICKER} +</h3>
                                    <p>Volume Burned</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-fact-inner style-two">
                                <div className="thumb">
                                    <img src="assets/img/fact/1.png" alt="img" />
                                </div>
                                <div className="details">
                                    {/* <h3>$ {DashD.total_withdraw}+ </h3> */}
                                    <h3>{DashD.total_withdraw_token ? kFormatter(DashD.total_withdraw_token) : 0} {process.env.REACT_APP_TICKER} +</h3>
                                    <p>Total Withdrawals</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Statestics;