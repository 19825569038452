import React, { useState, useEffect } from 'react';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import './pagination/style.css';
import axios from 'axios';
const AccoverviewBuySell = (props) => {
    // document.title = `Overview  - ${process.env.REACT_APP_APP_NAME}`
    // console.log("staking",props);

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);

    const onLegs = async () => {
        if (props.currentAccount) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap", submethod: "swapaddress", address: props.currentAccount, pageSize: 1000  })
                .then(res => {
                    setstaking(res.data.data)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onLegs();
    }, [props.currentAccount])

    const columns = [
        {
            name: "#",
            selector: "id",
            key: "id",
            width: "100px"
        },
        {
            name: "Buy/Sell",
            selector: "balance",
            cell: data => <><span className={`p-1 ${data['type'] === 0 ? 'bg-success text-white' : 'bg-danger text-white'}`}>{data["type"] === 0 ? 'Buy' : 'Sell'}</span></>,
            key: "balance",
            width: "100px"
        },
        {
            name: "Hash",
            selector: "hash",
            cell: data => <a target="_blank" href={`${process.env.REACT_APP_EXPLORER}tx/${data["hash"]}`}>{data["s_hash"]}</a>,
            key: "hash",
            width: "200px"
        },
        {
            name: process.env.REACT_APP_TICKER,
            selector: "amount_oxy",
            cell: data => <><span >{data["amount_oxy"] ? data["amount_oxy"].toFixed(4) : 0} {process.env.REACT_APP_TICKER}</span></>,
            key: "oxy",
            width: "180px"
        },
        {
            name: "BUSD",
            selector: "amount_busd",
            cell: data => <><span >{data["amount_busd"] ? data["amount_busd"].toFixed(4) : 0} BUSD</span></>,
            key: "busd",
            width: "180px"
        },
        {
            name: "Rate($)",
            selector: "rate",
            cell: data => <><span >$ {data["rate"]}</span></>,
            key: "rate",
            width: "180px"
        },

        {
            name: "Datetime",
            selector: "datetime",
            cell: data => <><span>{data["datetime"]}</span></>,
            key: "datetime",
            width: "160px"
        }
        // {
        //     name: "Status",
        //     selector: "status",
        //     key: "status",
        //     width: "200px"
        // }

    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div class="col-lg-12">
                <div class="td-sidebar" >
                    <div className="table-responsive text-nowrap tb">
                        <div class="widget widget_add">
                            <h3 class="widget-title style-white text-left stakingliner">Buy Sell</h3>
                            
                            <DataTableExtensions {...tableData}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    progressPending={loading}
                                    dense
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </>
    );
}

export default AccoverviewBuySell;