import React, { useState, useEffect, useContext } from 'react';
import Footer from './Footer';
import Header2 from './Header2';
import Dashmenu from './DashboardMenu'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import './pagination/style.css';
import axios from 'axios';
import "./styles.css";
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import { Oxyo2 } from '../Oxyo2';
const TeamBusiness = (props) => {
    const { account, formatAddress } = useContext(Oxyo2)

    document.title = `Referral Reward - ${process.env.REACT_APP_APP_NAME}`
    const [staking, setstaking] = useState([]);
    const [total64, settotal64] = useState(0);
    const [total60, settotal60] = useState(0);
    const [total40, settotal40] = useState(0);

    const [loading, setLoading] = useState(false);


    const onGetAllRecord = async (address, level) => {
        if (account) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "teambusiness", address: address, level: level })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data);
                    setstaking(res.data.arrysent)
                    settotal64(res.data.total64)
                    settotal60(res.data.total64.fortyPer)
                    settotal40(res.data.total64.sixtyPer)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onGetAllRecord(account,0);
    }, [account])
    const columns = [
        {
            name: "sr",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "Super Reward",
            selector: row => row["status"],
            cell: (data) => <><span className={`${data.bonus_income ? '' : 'text-danger'}`}>{data.bonus_income ? `Activated ${data.bonus_assigned_per}%` : ""}</span> </>,
            key: "status",
            width: "140px"
        },
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: 100
        },
        {
            name: "Position",
            selector: "position",
            cell: (data) => <span >{data.position ? data.position : '-'}</span>,
            key: "position",
            width: 100
        },
        {
            name: "address",
            selector: "address",
            cell: (data) => <span onClick={() => formatAddress(data.faddress)} >{data.address} <i className='far fa-copy' type="button" ></i></span>,
            key: "address",
            width: "300px"
        },
        {
            name: "Staking",
            selector: "tstaking",
            cell: (data) => <span >$ {data.tstaking}</span>,
            key: "tstaking",
            width: "150px"
        },
        {
            name: "Team Business($)",
            selector: "teambusiness",
            cell: (data) => <span >$ {data.teambusiness}</span>,
            key: "teambusiness",
            width: "150px"
        },
        {
            name: "Total Member ",
            selector: "teamcount",
            key: "teamcount",
            width: "150px"
        },

        {
            name: "Activation ",
            selector: "activedatetime",
            key: "activedatetime",
            width: "200px"
        },
        {
            name: "Joining",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        }


    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>
            <Header2 />
            <div class="blog-area bg-blue pd-top-100 pd-bottom-100 dashboard_box_image">
                <div class="container" style={{ marginTop: "20px" }}>
                    <div class="fact-area bg-cover bg-overlay-2 text-center">
                        <div class="container">
                            <div class="row mb-5">
                                <Adrbox />
                                <Blcbox />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="td-sidebar">
                                <Dashmenu />
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="td-sidebar" >
                                <div className="table-responsive text-nowrap tb">
                                    <div class="widget widget_add">
                                        <div className='justify-content-between stakingliner d-lg-flex p-0 d-md-block d-sm-block '>
                                            <h3 class="widget-title border-0 text-left p-0" >Team Bussiness
                                           
                                            </h3>
                                            <span>
                                                <select className="form-select form-select-lg mb-3" onChange={(e) => onGetAllRecord(account, Number(e.target.value))} aria-label=".form-select-lg example">
                                                    <option selected value="0">1</option>
                                                    <option value="1">2</option>
                                                    <option value="2">3</option>
                                                    <option value="3">4</option>
                                                    <option value="4">5</option>
                                                    <option value="5">6</option>
                                                    <option value="6">7</option>
                                                    <option value="7">8</option>
                                                    <option value="8">9</option>
                                                    <option value="9">10</option>
                                                    <option value="10">11</option>
                                                    <option value="11">12</option>
                                                    <option value="12">13</option>
                                                    <option value="13">14</option>
                                                    <option value="14">15</option>
                                                    <option value="15">16</option>
                                                    <option value="16">17</option>
                                                    <option value="17">18</option>
                                                </select>
                                            </span>
                                            <h3 class="widget-title border-0 text-left p-0" >Leadership </h3>
                                            <p>40: {total40 ? total40.toFixed(4) : 0}</p>
                                            <p>60: {total60 ? total60.toFixed(4) : 0}</p>
                                        </div>

                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={true}
                                                pagination
                                                progressPending={loading}
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TeamBusiness;



