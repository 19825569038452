import React, { useState, useEffect } from 'react';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import './pagination/style.css';
import axios from 'axios';
const AccoverviewStatement = (props) => {
    // document.title = `Overview  - ${process.env.REACT_APP_APP_NAME}`
    // console.log("staking",props);

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);


    const onGetAllRecord = async () => {
        if (props.currentAccount) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord",submethod:"statement", address: props.currentAccount })
                .then(res => {
                 
                    setstaking(res.data)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onGetAllRecord();
    }, [props.currentAccount])

    const columns = [
        {
            name: "#",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        },
        // {
        //     name: "address",
        //     selector: "address",
        //     key: "address",
        //     width: "300px"
        // },
        {
            name: "Description",
            selector: "description",
            key: "description",
            width: "480px"
        },
        {
            name: "Credit($)",
            selector: "credit",
            key: "credit",
            width: "180px"
        },
        {
            name: "Debit($)",
            selector: "debit",
            key: "debit",
            width: "180px"
        },
        {
            name: "Balance($)",
            selector: "balance",
            key: "balance",
             width: "150px"
        } 
        // {
        //     name: "Status",
        //     selector: "status",
        //     key: "status",
        //     width: "200px"
        // }

    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div class="col-lg-12">
                <div class="td-sidebar" >
                    <div className="table-responsive text-nowrap tb">
                        <div class="widget widget_add">
                            <h3 class="widget-title style-white text-left stakingliner" >Account Statement</h3>

                            <DataTableExtensions {...tableData}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    progressPending={loading}
                                    dense
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </>
    );
}

export default AccoverviewStatement;