import React from 'react';

function Footer() {

    return (
        <>
            <div>
                <div className="footer-bottom bg-blue">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-lg-12 align-self-center">
                                <p>CopyRight &#169; {(new Date().getFullYear())}. All right reserved &amp; Designed & Developed by {process.env.REACT_APP_APP_NAME}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
            </div>

        </>
    );
}

export default Footer;