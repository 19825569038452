import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Oxyo2 } from '../Oxyo2';
import style from './timer/App.module.css';
const Blcbox = (props) => {
    const {  userData,  RATE, UDashD } = useContext(Oxyo2);
    const [Balance, setBalance] = useState(0)
    useEffect(() => {
        var bal = (userData.wallet_balance - userData.total_withdrawal) - UDashD.pending_withdraw;
        if (bal.toString() !== 'NaN') {
            setBalance(bal.toFixed(2))
        }
        // console.log("baaaal",bal,UDashD);
    }, [userData,UDashD])
    return (
        <>

            <div className="col-lg-3">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="td-sidebar" id="dtBasicExample">
                            <div className="widget widget_add">
                                <h3 className="widget-title style-white" >Leadership Rank</h3>
                                <div className="withdraw">
                                    <img src="assets/img/logo.png" alt="logo" style={{ width: "77%" }} />
                                    <h3 className={style.walletbalance}>{userData.position ? userData.position : "No Rank"}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.nobal ?
                <div className="col-lg-3">
                    <div className="row justify-content-center mb-4">
                        <div className="col-lg-12">
                            <div className="td-sidebar" id="dtBasicExample">
                                <div className="widget widget_add">
                                    <h3 className="widget-title style-white" >Rate</h3>
                                    <div className="withdraw">
                                        <h3 className={style.walletbalance}> ${RATE} </h3>
                                        <h3 className="mt-3 mb-3 text-white"></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="col-lg-3">
                    <div className="row justify-content-center mb-4">
                        <div className="col-lg-12">
                            <div className="td-sidebar" id="dtBasicExample">
                                <div className="widget widget_add">
                                    <h3 className="widget-title style-white" >Wallet Balance</h3>
                                    <div className="withdraw">
                                        <h3 className={style.walletbalance}> ${Balance} </h3>
                                        <Link to='/Withdraw' className="btn btn-base">OXYO2 Buy</Link>
                                        <h3 className="mt-3 mb-3 text-white"></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Blcbox;