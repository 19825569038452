import React, { useContext, useState } from 'react';
import axios from 'axios';
import validator from 'validator'
import { Oxyo2 } from '../Oxyo2';

function Subscribe() {
    const { toastError, toastSuccess, toastInfo } = useContext(Oxyo2);

    const [email, setemail] = useState('')
    const onclickhandler = async () => {
        if (validator.isEmail(email)) {

            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "email", submethod: "add", email: email })
                .then(res => {
                    if (res.data === 1) {
                        toastSuccess('Email Submitted!');
                    } else if (res.data === 0) {
                        toastInfo('Email Already Exist!');
                    } else {
                        toastError('Error Occured!');
                    }
                })

        } else {
            toastError('Invalid Email!');
        }
    }

    return (
        <>
            <div className="call-to-action-area bg-overlay pd-top-115 pd-bottom-120" style={{ backgroundImage: "url(assets/img/home-2/fact/bg.jpg" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 align-self-center">
                            <div className="match-table-inner gradient-bg" >
                                <div className="section-title style-white mb-0 text-center">

                                    <h2 className="title">Weekly newsletter</h2>
                                    <p className="content">Trending OXYO2 news, how-to guides, stories from the world of cryptocurrency from community contributors
                                    </p>
                                    <div className="footer-area">

                                        <div className="single-subscribe-inner" style={{ marginBottom: "20px", marginTop: "-20px" }}>
                                            <input type="text" placeholder="Enter Your E-mail" onChange={(e) => setemail(e.target.value)} />
                                            <button className="btn btn-base-2" style={{ marginTop: "0px" }} onClick={(e) => { onclickhandler(e); }}>Subscribe</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Subscribe;