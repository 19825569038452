import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Statestics from "./Statestics";
import Features from "./Features";
import LearnMoreFeatures from "./LearnMoreFeatures";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Process from "./Process";
import Faq from "./Faq";
import Subscribe from "./Subscribe";
// import SwapInfo from './SwapInfo';
import Swap from "./Swap";
import Swap2 from "./Swap2";
import { Oxyo2 } from "../Oxyo2";
// import FormInfo from "./FormInfo";

const Home = (props) => {
    const { account, library, copyaddress, setting, connectMetamask, connectWallet, logout, formatAddress, DashD, userData, userRegistered, RATE } =
        useContext(Oxyo2);

    useEffect(() => {
        if (setting.website_popup) {
            window.$("#exampleModalCenter1").modal("show");
            setpopupcontent(setting.website_popup_content);
            setpopuptitle(setting.website_popup_title);
        }
        setTokenrate(RATE);
        setTotalstake(Range / tokenrate);
        // onGetUser();
    }, [setting, RATE]);
    // useEffect(() => {
    //     onGetUser();
    // }, [account])
    // console.log("im from home",setting.social_media) ;
    // useEffect(() => {
    //     if(setting.social_media){
    //         console.log("effect ",setting.social_media[0]);
    //     }else{
    //         console.log("else");
    //     }
    // }, [props])
    document.title = `Home - ${process.env.REACT_APP_APP_NAME}`;
    const [Range, setRange] = useState(20);
    // const [Response, setResponse] = useState("");
    const [tokenrate, setTokenrate] = useState(RATE);
    const [totalstake, setTotalstake] = useState(Range / tokenrate);
    // const [CheckTotalRes, setCheckTotalRes] = useState('');
    const [userStatus, setuserStatus] = useState();
    const [userRef, setuserRef] = useState();

    const [popupcontent, setpopupcontent] = useState("");
    const [popuptitle, setpopuptitle] = useState("");
    // const [hash, sethash] = useState("");
    const history = useNavigate();
    const [isSuccess, setisSuccess] = useState(0);

    //console.log("date formatetet",account);
    const html = setting.connect_button_message ? setting.connect_button_message : "<span></span>";
    const titlehtml = popuptitle ? popuptitle : "<span></span>";
    const popupcontentHTML = popupcontent ? popupcontent : "<span></span>";
    // console.log("setting  asf",setting.contract_address);
    //web 3
    useEffect(() => {
        setTimeout(() => {
            // console.log("Timeout");
            setTotalstake(Range / tokenrate);
        }, 1);
    });

    const closemodal = async (e) => {
        connectMetamask();
        window.$("#closemodal").trigger("click");
    };

    const walletconn = async (e) => {
        connectWallet();
        window.$("#closemodal").trigger("click");
    };

    const checktxs = async (hash) => {
        var tx = await library.eth.getTransactionReceipt(hash);
        // console.log("tx", tx);

        // console.log("this is hashs ssasl");
        if (tx) {
            setisSuccess(1);
            var getBD = await library.eth.getBlock(tx.blockNumber);
            await axios
                .post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "updatetransaction", hash: hash, timestamp: getBD.timestamp })
                .then((res) => {
                    console.log(res.data);
                });
            return tx.status;
        } else {
            setisSuccess(2);
            setTimeout(async () => {
                await checktxs(hash);
            }, 1);
        }
    };

    const on_logout = () => {
        // console.log("lopgout");
        logout();
        setuserStatus(1);
        setuserRef("");
        history("/");
    };

    return (
        <>
            <div className="navbar-area navbar-area-2">
                <nav className="navbar navbar-area navbar-expand-lg">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <button
                                className="menu toggle-btn d-block d-lg-none"
                                data-target="#edumint_main_menu"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-left"></span>
                                <span className="icon-right"></span>
                            </button>
                        </div>
                        <div className="logo">
                            <a href="/">
                                <img src="assets/img/logo.png" alt="img" />
                            </a>
                        </div>
                        <div className="nav-left-part"></div>

                        <div className="collapse navbar-collapse" id="edumint_main_menu">
                            <ul className="navbar-nav menu-open text-center">
                                <li className="current-menu-item">
                                    <a href="#tokendetails">Token</a>
                                </li>
                                <li className="current-menu-item">
                                    <a href="#staking">Stake</a>
                                </li>
                                <li className="current-menu-item">
                                    <a href="#benefits">Benefits</a>
                                </li>
                                <li className="current-menu-item">
                                    <a href="https://etherauthority.io/oxyo2-token-smart-contract-audit/" target="_blank">
                                        Audit Report
                                    </a>
                                </li>
                                <li className="current-menu-item">
                                    <a href="https://oxyzentoken.gitbook.io/oxyo2-whitepaper/" target="_blank">
                                        Whitepaper
                                    </a>
                                </li>
                                <li className="current-menu-item">
                                    <a href="https://support.oxyo2.org/" target="_blank">Support</a>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown ">
                        <button className="dropbtn btn btn-base">
                            <a href="#swaps_page">Swap</a>
                        </button>
                            {!setting.connect_button_status ? (
                                <>
                                    {account ? (
                                        <>
                                            {userData.reff_address ? (
                                                <button className="dropbtn btn btn-base">
                                                    {account ? formatAddress(account) : ""} <i className="fas fa-arrow-circle-right ms-2"></i>
                                                </button>
                                            ) : (
                                                <>
                                                    &nbsp;&nbsp;
                                                    <button className="dropbtn btn btn-base">
                                                        <a href="#staking">Register</a>
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <button className="dropbtn btn btn-base" data-toggle="modal" data-target="#exampleModalCenter">
                                            Connect <i className="fas fa-arrow-circle-right ms-2"></i>
                                        </button>
                                    )}
                                    {userData.reff_address ? (
                                        <div className="dropdown-content mt-1">
                                            <button className="btn btn-base my-2" style={{ color: "#fff" }}>
                                                <a href="/dashboard">Dashboard</a>
                                            </button>
                                            <button onClick={() => on_logout()} className="btn btn-base my-2" style={{ color: "#fff" }}>
                                                Logout
                                            </button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <button className="btn btn-base" data-toggle="modal" data-target="#connectbuttonadmin" style={{ fontSize: "17px" }}>
                                    Connect<i className="fas fa-arrow-circle-right ms-2"></i>
                                </button>
                            )}
                        </div>
                    </div>
                </nav>
            </div>

            <div className="banner-area banner-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 align-self-center">
                            <div className="banner-inner">
                                <h6 className="b-animate-1 sub-title">OXYO2 Cloud Storage</h6>
                                <h1 className="b-animate-2 title">OXYO2 build Worlds First Decentralized cloud storage </h1>
                                <p className="b-animate-4 me-5">
                                    {" "}
                                    oxyO2 is creating its own Techno world. We aim to build a Worlds First Decentralized cloud storage data centers
                                    that everyone can participate and get the advantage
                                </p>
                                <h4 className="b-animate-4 text-white text-break" onClick={() => copyaddress(setting.token_address)}>
                                    Contract {setting.token_address} <i type="button" className="fas fa-copy" title="click to copy address"></i>
                                </h4>
                                <div className="btn-wrap">
                                    <a className="btn btn-base b-animate-4" href="#stakingsec">
                                        Stake now <i className="fas fa-arrow-circle-right ms-2"></i>
                                    </a>
                                    &nbsp;
                                    <a className="btn btn-base b-animate-4" href="#buy_now_page" id="">
                                        Buy Now <i className="fas fa-arrow-circle-right ms-2"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 align-self-center">
                            <div className="thumb b-animate-thumb d-lg-block d-none">
                                <img src="assets/img/oxyo2-token.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Statestics />
            <section className="about_area bg-cover bg-overlay-1">
                <div className="video-area bg-overlay pd-top-100 pd-bottom-100" style={{ backgroundImage: "url(assets/img/about/bg.png)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 align-self-center mb-5 mb-lg-0">
                                <div className="thumb video-area-inner">
                                    <span className="shadow"></span>
                                    <img className="w-100" src="assets/img/about.png" alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="section-title style-white ms-lg-4 ms-0 mb-0">
                                    <h1 className="sub-title">OXYO2</h1>
                                    <p className="content">
                                        One Technology, which is completely changing the entire transaction system globally and helping every
                                        individual to make secure payments is called Blockchain. With help of this amazing Technology, Team oxyO2 is
                                        creating its own Techno world. We aim to build a Worlds First Decentralized cloud storage data centers that
                                        everyone can participate and get the advantage. In the Years to come every smart phone or Laptop user will use
                                        oxyO2 for decentralized Data Storage. Besides this oxyO2 can be used for Utility Payments, peer to peer
                                        exchange across the Globe.{" "}
                                    </p>
                                    <p>
                                        <br />
                                        oxyO2 is decentralized cryptocurrency which will be used as a utility token. The maximum supply of oxyO2 is
                                        1000 Million, which will be minted and distributed among potential oxyO2 holders during various processes.
                                        oxyO2 will use the finest and safest payment process to increase financial security and transparency. With the
                                        help of Products and services of oxyO2, the oxyO2 community can extreme benefits from decentralized finance
                                        and other services like DEFI, Staking, Fantasy Game, and many more...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="stakingsec">
                <LearnMoreFeatures />
            </div>
            {/* <SwapInfo/> */}
            {/* <Swap/> */}
            {/* <FormInfo /> */}
            <div id="swaps_page">
                    <Swap2 />
                </div>
            {/* <div id="buy_now_page">
                <Swap />
            </div> */}

            {/*             
            <WhoWeAre /> */}
            <div
                className="testimonial-area bg-overlay-2 pd-top-100 pd-bottom-100"
                style={{ backgroundImage: "url(assets/img/testimonial/bg.jpg)" }}
                id="tokendetails"
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="section-title style-white text-center">
                            <h5 className="sub-title">Token Details</h5>
                            <h2 className="title">OXYO2 Token</h2>
                            <p></p>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <h4>NAME : OXYO2 ({process.env.REACT_APP_TICKER}) </h4>
                            <h4> NETWORK : BINANCE CHAIN (BEP20) </h4>
                            <h4>DECIMALS : 18</h4>
                            <h4>MAXIMUM SUPPLY: 1,000,000,000 </h4>
                            <h4>BURNING : 50% </h4>
                            <h4>CONTRACT ADDRESS : </h4>
                            <h5 className="text-break">{setting.token_address}</h5>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="thumb b-animate-thumb d-lg-block d-none">
                                <img src="assets/img/oxyo2-token.png" alt="img" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-6">
                            <div className="single-fact-inner default-card">
                                <div className="default-card-inner" />
                                <div className="thumb text-center">
                                    <img src="assets/img/fact/1.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h3>
                                        <span className="6">{DashD.total_staker ? DashD.total_staker : 0}</span>
                                    </h3>
                                    <p>Total Members</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-fact-inner default-card default-card-active">
                                <div className="default-card-inner" />
                                <div className="thumb text-center">
                                    <img src="assets/img/fact/2.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h3>
                                        <span className="6"> {DashD.total_burn_token ? DashD.total_burn_token : 0}</span>+
                                    </h3>
                                    <p>Total BURN</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-fact-inner default-card">
                                <div className="default-card-inner" />
                                <div className="thumb text-center">
                                    <img src="assets/img/fact/3.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h3>
                                        <span className="6">$ {DashD.total_withdraw ? DashD.total_withdraw : 0}</span>+{" "}
                                    </h3>
                                    <p>Total Withdrawal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Process />
            <Features />

            <div className="profit-area bg-overlay pd-top-95 pd-bottom-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="innovations-content">
                                <div className="section-title style-white text-center">
                                    {/* <!-- <h5 className="sub-title">SPORTS BETTING</h5> --> */}
                                    <h2 className="title">User-friendly dashboard</h2>
                                    <p className="content">
                                        Your personal dashboard provides interactive visualisation of your structure in online mode. You can monitor
                                        the number of your partners, the current state of your activated slots, and all the other significant data in
                                        real time.
                                    </p>
                                </div>
                                <div className="img-wrapper img-shadow">
                                    <img src="assets/img/oxyo2app.png" alt="not available" style={{ height: "100%", width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="featured-area bg-blue text-center pd-top-95 pd-bottom-30">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 align-self-center">
                            <div className="section-title style-white text-center">
                                <h5 className="sub-title">SWOT</h5>
                                <h2 className="title">SWOT Analysis</h2>
                                {/* <p className="content mx-0 mx-lg-5">Cubilia dui pretium inventore ab atque aliquid mollit aliquid laborum? Congue dicta! 
                                    Laboriosam aliqua nulla dictum egestami sapiente! Nisi vivamus nostrud</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6">
                            <div className="single-feature-inner style-two">
                                <div className="thumb">
                                    <img src="assets/img/feature/1.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Stregths</h4>
                                    <p>
                                        Know Your Customer
                                        <br />
                                        Anti-Money Laudnering
                                        <br />
                                        Counter Terrorist Financing
                                        <br />
                                        Financial Audits
                                        <br />
                                        Improving Security
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-feature-inner style-two">
                                <div className="thumb">
                                    <img src="assets/img/feature/2.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Weakness</h4>
                                    <p>
                                        Hacks
                                        <br />
                                        Data Leaks
                                        <br />
                                        Price Manipulations
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-feature-inner style-two">
                                <div className="thumb">
                                    <img src="assets/img/feature/5.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Opportunities</h4>
                                    <p>
                                        Decentralization
                                        <br />
                                        Improved Customer Journey
                                        <br />
                                        Cold Storage <br />
                                        New Services <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="single-feature-inner style-two">
                                <div className="thumb">
                                    <img src="assets/img/feature/6.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Threats</h4>
                                    <p>
                                        Atomic Swaps
                                        <br />
                                        Low Volumes
                                        <br />
                                        Customer Distrust
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Faq />
            {/* <ContactUsHome /> */}
            {/* <SocialMedia social_media={social_media} /> */}
            <Subscribe />

            {/* <BlogPageHome />
            <Partners /> */}
            <Footer />
            <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                style={{ paddingRight: "8px" }}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    {/* <div className="" style={{ color: "white" }}> */}
                    <button
                        type="button"
                        className="close d-none"
                        id="closemodal"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ backgroundColor: "#24006c", border: "none", color: "white" }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="connectWalletPop">
                        <div className="connectPopCard" onClick={() => closemodal()}>
                            <div className="text-center connectPopInner">
                                <img src="assets/img/metamask.png" alt="" />
                                <div className="single-fact-inner default-card default-card-active" style={{ marginTop: "25px" }}>
                                    <div className="details">
                                        {" "}
                                        <h3>Metamask</h3>
                                    </div>
                                </div>
                                <div className="single-about-wrap" style={{ marginTop: "-35px" }}>
                                    <div className="details">
                                        {" "}
                                        <h5>Connect to your MetaMask Wallet</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="connectPopCard" onClick={() => walletconn()}>
                            <div className="text-center connectPopInner">
                                <img src="assets/img/walletconnect.png" alt="" />
                                <div className="single-fact-inner default-card default-card-active" style={{ marginTop: "25px" }}>
                                    <div className="details">
                                        {" "}
                                        <h3>Walletconnect</h3>
                                    </div>
                                </div>
                                <div className="single-about-wrap" style={{ marginTop: "-35px" }}>
                                    <div className="details">
                                        {" "}
                                        <h5>Connect to your Webconnect Wallet</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="connectPopCard" onClick={() => closemodal()}>
                            <div className="text-center connectPopInner">
                                <img src="assets/img/tokenpocket.png" alt="" />
                                <div className="single-fact-inner default-card default-card-active" style={{ marginTop: "25px" }}>
                                    <div className="details">
                                        {" "}
                                        <h3>Token Pocket</h3>
                                    </div>
                                </div>
                                <div className="single-about-wrap" style={{ marginTop: "-35px" }}>
                                    <div className="details">
                                        {" "}
                                        <h5>Connect to your Token Pocket wallet</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="connectPopCard" onClick={() => closemodal()}>
                            <div className="text-center connectPopInner">
                                <img src="assets/img/binancewallet.png" alt="" />
                                <div className="single-fact-inner default-card default-card-active" style={{ marginTop: "25px" }}>

                                    <div className="details"> <h3>Binance wallet</h3></div>
                                </div>
                                <div className="single-about-wrap" style={{ marginTop: "-35px" }}>
                                    <div className="details"> <h5>Connect to your Binance wallet</h5></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="connectPopCard" onClick={() => closemodal()}>
                            <div className="text-center connectPopInner">
                                <img src="assets/img/trustwallet.png" alt="" />
                                <div className="single-fact-inner default-card default-card-active" style={{ marginTop: "25px" }}>
                                    <div className="details">
                                        {" "}
                                        <h3>Trust Wallet</h3>
                                    </div>
                                </div>
                                <div className="single-about-wrap" style={{ marginTop: "-35px" }}>
                                    <div className="details">
                                        {" "}
                                        <h5>Connect to your Trust Wallet</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="connectPopCard">
                            <div className="single-fact-inner default-card default-card-active" style={{ marginTop: "25px" }}></div>
                            <div className="single-about-wrap" style={{ marginTop: "-35px" }}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="exampleModalCenter1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="connectWalletPop1">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 style={{ color: "white" }}>{parse(titlehtml)}</h4>
                            </div>
                            <div className="modal-body text-center">
                                <div className="details">
                                    {" "}
                                    <h5 style={{ color: "white" }}>{parse(popupcontentHTML)}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* connect button admin modal */}
            <div
                className="modal fade"
                id="connectbuttonadmin"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="connectWalletPop1">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 style={{ color: "white" }}>Info</h4>
                            </div>
                            <div className="modal-body text-center">
                                <div className="details">
                                    {" "}
                                    <h5 style={{ color: "white" }}>{parse(html)}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
