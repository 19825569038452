import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Oxyo2 } from "../Oxyo2";
function Dashmenu() {
    const { userData, UDashD } = useContext(Oxyo2);

    return (
        <>
            <div className="widget widget_catagory">
                <h4 className="widget-title">Menu</h4>
                <ul className="catagory-items" style={{ fontSize: "22px" }}>
                    <li key={1}>
                        <a href="/dashboard">Dashboard</a>
                    </li>
                    <li key={2}>
                        <Link to="/stakings"> Staking</Link>
                    </li>
                    <li key={3}>
                        <Link to="/stakingReward"> Staking Reward</Link>
                    </li>
                    <li key={4}>
                        <Link to="/LeadershipReward"> Leadership Reward</Link>
                    </li>
                    <li key={5}>
                        <Link to="/ReferralReward"> Referral Reward</Link>
                    </li>
                    {userData.bonus_income || UDashD.super_bonus ? (
                        <li key={13}>
                            <Link to="/BonusIncome"> Bonus Income</Link>
                        </li>
                    ) : (
                        ""
                    )}
                    <li key={12}>
                        <Link to="/LeadershipHistory"> Leadership History</Link>
                    </li>
                    <li key={6}>
                        <Link to="/WithdrawalList"> Coin Bought</Link>
                    </li>
                    <li key={7}>
                        <Link to="/Statement">Account Statement</Link>
                    </li>
                    <li key={8}>
                        <Link to="/TeamBusiness"> Team Business</Link>
                    </li>
                    <li key={9}>
                        <Link to="/UsersDate">Leg Business Report</Link>
                    </li>
                    <li key={10}>
                        <Link to="/BuySell">Buy/Sell</Link>
                    </li>
                    <li key={13}>
                        <Link to="/FlushedIncome">Flushed Income</Link>
                    </li>
                    <li key={15}>
                        <Link to="/SwapList">Swap List</Link>
                    </li>
                    <li key={11}>
                        <a href="/#faqs ">FAQ's</a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Dashmenu;
