import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Oxyo2 } from "../Oxyo2";
import parse from "html-react-parser";
import { MDBInputNumeric } from "mdbreact";

function Swap2() {
    // console.log("props from swap ", props);
    const { account, library, setting, toastError, toastSuccess, toastInfo, toastDismiss, RATE, copyaddress } = useContext(Oxyo2);

    const [bln, setbln] = useState(0);
    const [blnbusd, setblnbusd] = useState(0);
    const re = /^-?\d+(\.\d+)?$/;
    const [buyOXY, setbuyOXY] = useState(0);
    const [buyBUSD, setbuyBUSD] = useState(0);

    const [swaphash, setswaphash] = useState("");
    const [swapvalue, setswapvalue] = useState("");
    const [dispswapvalue, setdispswapvalue] = useState("");
    const [swapspinner, setswapspinner] = useState(false);
    const [swaptimerspinner, setswaptimerspinner] = useState(true);
    const [isinwhitelist, setisinwhitelist] = useState(false);
    const [islimitexided, setislimitexided] = useState(false);
    const [disptimer, setdisptimer] = useState(true);
    const [disptimer2, setdisptimer2] = useState(true);
    const [timer, settimer] = useState(false);
    const [timer2, settimer2] = useState(false);
    const [timestemp, settimestemp] = useState(null);
    const [timestemp2, settimestemp2] = useState(null);
    const [totalswap, settotalswap] = useState("0");
    const [remaining, setRemaining] = useState("0");
    const [dailyRemaining, setDailyRemaining] = useState("0");
    const [whitelistlimit, setwhitelistlimit] = useState("0");
    const [swapMaintenanceMsg, setSwapMaintenanceMsg] = useState("");
    const [swapdatalength, setswapdatalength] = useState(null);
    const [whitelistdatalength, setwhitelistdatalength] = useState(null);

    const [second, setsecond] = useState(0);
    const [minute, setminute] = useState(0);
    const [hour, sethour] = useState(0);

    const [second2, setsecond2] = useState(0);
    const [minute2, setminute2] = useState(0);
    const [hour2, sethour2] = useState(0);

    const [hidebtn, sethidebtn] = useState(false);

    const [selectedItem2, setSelectedItem2] = useState("");
    const changeSwapValue = (e) => {
        if (e === "" || re.test(e)) {
            setswapvalue(e);
        }
    };

    const getSwapData = async () => {
        if (account) {
            await axios
                .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                    method: "swap2",
                    submethod: "swapaddress",
                    address: account,
                    pageSize: 1000,
                    checklen: true,
                })
                .then((res) => {
                    if (res.data.error) {
                        console.log("error : ", res.data.error);
                    } else {
                        setswapdatalength(res.data.swaplen);
                        setwhitelistdatalength(res.data.whitelistlen);
                    }
                });
        }
    };

    const aproveCall = async () => {
        if (setting && setting.old_token_abi && setting.old_token_address) {
            let contractAP1 = new library.eth.Contract(JSON.parse(setting.old_token_abi), setting.old_token_address);
            await contractAP1.methods
                .approve(setting.swap_contract_address, library.utils.toWei(swapvalue, "ether"))
                .send({ from: account })
                .then(async (res) => {
                    if (res.transactionHash) {
                        toastSuccess("Transaction done.");
                        contractCall();
                    } else {
                        sethidebtn(false);
                        setswapspinner(false);
                        toastError("Transaction Canceled!");
                    }
                });
        }
    };

    const contractCall = async () => {
        if (setting && setting.swap_contract_abi && setting.swap_contract_address) {
            let contractswap = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
            await contractswap.methods
                .Reclaimed(library.utils.toWei(swapvalue, "ether"))
                .send({ from: account })
                .then(async (res) => {
                    if (res.transactionHash) {
                        await axios
                            .post(process.env.REACT_APP_BACKEND_LINK, {
                                method: "swap2",
                                submethod: "insert",
                                hash: res.transactionHash,
                                amount: swapvalue,
                                address: account,
                                status: 1,
                            })
                            .then((res2) => {
                                if (res2.data.error) {
                                    sethidebtn(false);
                                    toastError(res2.data.error);
                                } else {
                                    sethidebtn(false);
                                    setswaphash(res.transactionHash);
                                    setdispswapvalue(swapvalue);
                                    setswapvalue("");
                                    toastSuccess("Transaction done.");
                                    getBalance();
                                    getTotalswap();
                                    setswapspinner(false);
                                }
                            });
                    } else {
                        sethidebtn(false);
                        setswapspinner(false);
                        toastError("Transaction Canceled!");
                    }
                })
                .catch((erro) => {
                    sethidebtn(false);
                    setswapspinner(false);
                    toastError("Transaction Canceled!");
                });
        }
    };

    const swap = async () => {
        if (swapvalue) {
            if (swapvalue <= bln) {
                setswapspinner(true);
                sethidebtn(true);
                setswaphash("");
                setdispswapvalue("");
                try {
                    let contractswap = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
                    let conversioncount = library.utils.fromWei(
                        await contractswap.methods
                            .conversionCount(account)
                            .call()
                            .catch((erro) => {
                                sethidebtn(false);
                                setswapspinner(false);
                                toastError("Transaction Canceled!");
                            }),
                        "ether"
                    );
                    let dailyconversionlimit = library.utils.fromWei(
                        await contractswap.methods
                            .dailyConversionLimit()
                            .call()
                            .catch((erro) => {
                                sethidebtn(false);
                                setswapspinner(false);
                                toastError("Transaction Canceled!");
                            }),
                        "ether"
                    );
                    // if (Number(conversioncount) + Number(swapvalue) <= Number(dailyconversionlimit)) {
                    // var token_contract = await new library.eth.Contract(JSON.parse(setting.old_token_abi), setting.old_token_address);
                    // var checkALW = await token_contract.methods
                    //     .allowance(library.utils.toChecksumAddress(account), library.utils.toChecksumAddress(setting.swap_contract_address))
                    //     .call();
                    // if (Number(checkALW) === 0) {
                    //     aproveCall();
                    // } else {
                    //     contractCall();
                    // }
                    if (whitelistdatalength > 0) {
                        if (swapdatalength > 0) {
                            contractCall();
                        } else {
                            aproveCall();
                        }
                    } else {
                        if (swapdatalength > 0) {
                            contractCall();
                        } else {
                            aproveCall();
                        }
                    }
                    // } else {
                    //     sethidebtn(false);
                    //     setswapspinner(false);
                    //     toastError("Swap value exceeded daily conversion limit!");
                    // }
                } catch (err) {
                    sethidebtn(false);
                    setswapspinner(false);
                    console.log("err", err);
                    toastError(err.message);
                }
            } else {
                sethidebtn(false);
                setswapspinner(false);
                toastError("Insuficiant balance!");
            }
        } else {
            sethidebtn(false);
            setswapspinner(false);
            toastError("Please enter value!");
        }
    };
    const max = async () => {
        if (setting && setting.swap_contract_abi && setting.swap_contract_address) {
            let contractswap = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
            let dailyconversionlimit = library.utils.fromWei(await contractswap.methods.dailyConversionLimit().call(), "ether");
            setswapvalue(dailyconversionlimit);
        }
    };

    const getBalance = async () => {
        if (setting && setting.old_token_abi && setting.old_token_address) {
            if (account) {
                let contract = new library.eth.Contract(JSON.parse(setting.old_token_abi), setting.old_token_address);
                const balinwei = await contract.methods
                    .balanceOf(account)
                    .call()
                    .then(async (res) => {
                        setbln(Number(library.utils.fromWei(res, "ether")));
                        return res;
                    })
                    .catch((erro) => {
                        // toastError("Transaction Canceled!");
                    });
                return balinwei;
            }
        }
    };
    const getWhitelistlimit = async () => {
        if (setting && setting.swap_contract_abi && setting.swap_contract_address) {
            if (account) {
                let contract = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
                let conversionlimit = library.utils.fromWei(await contract.methods.whitelistConversionLimit().call(), "ether");
                setwhitelistlimit(conversionlimit);
            }
        }
    };
    const getTotalswap = async () => {
        if (setting && setting.swap_contract_abi && setting.swap_contract_address) {
            if (account) {
                let contract = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
                let conversioncount = library.utils.fromWei(
                    await contract.methods
                        .conversionCount(account)
                        .call()
                        .catch((erro) => {}),
                    "ether"
                );
                settotalswap(conversioncount);
                let dailyconversionlimit = library.utils.fromWei(
                    await contract.methods
                        .dailyConversionLimit()
                        .call()
                        .catch((erro) => {}),
                    "ether"
                );
                setRemaining((Number(dailyconversionlimit) - Number(conversioncount)).toString());
                let perdaylimit = library.utils.fromWei(
                    await contract.methods
                        .perDayLimit()
                        .call()
                        .catch((erro) => {}),
                    "ether"
                );
                let totalcount = library.utils.fromWei(
                    await contract.methods
                        .totalCount()
                        .call()
                        .catch((erro) => {}),
                    "ether"
                );
                setDailyRemaining(Number(perdaylimit) - Number(totalcount));
            }
        }
    };

    const addInWhiteList = async () => {
        try {
            if (account && setting) {
                let contract = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
                let conversionlimit = library.utils.fromWei(await contract.methods.whitelistConversionLimit().call(), "ether");
                if (Number(bln) >= Number(conversionlimit)) {
                    setswapspinner(true);
                    if (conversionlimit) {
                        let contractAP1 = new library.eth.Contract(JSON.parse(setting.old_token_abi), setting.old_token_address);
                        await contractAP1.methods
                            .approve(setting.swap_contract_address, library.utils.toWei(conversionlimit, "ether"))
                            .send({ from: account })
                            .then(async (res) => {
                                if (res.transactionHash) {
                                    toastSuccess("Approved!");
                                    let contractAP2 = new library.eth.Contract(JSON.parse(setting.token_abi), setting.token_address);
                                    await contractAP2.methods
                                        .approve(setting.swap_contract_address, library.utils.toWei(conversionlimit, "ether"))
                                        .send({ from: account })
                                        .then(async (res) => {
                                            if (res.transactionHash) {
                                                toastSuccess("Approved!");
                                                await contract.methods
                                                    .addToWhitelist()
                                                    .send({ from: account })
                                                    .then(async (res2) => {
                                                        if (res2.transactionHash) {
                                                            await axios
                                                                .post(process.env.REACT_APP_BACKEND_LINK, {
                                                                    method: "swap2",
                                                                    submethod: "insert",
                                                                    hash: res2.transactionHash,
                                                                    amount: conversionlimit,
                                                                    address: account,
                                                                    status: 0,
                                                                })
                                                                .then(async (res) => {
                                                                    if (res.data.error) {
                                                                        toastError(res.data.error);
                                                                        setswapspinner(false);
                                                                    } else {
                                                                        await checkwhitelist();
                                                                        setswapspinner(false);
                                                                        getTotalswap();
                                                                        toastSuccess("Transaction done.");
                                                                    }
                                                                });
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        setswapspinner(false);
                                                        console.log("error", error);
                                                        toastError(error.message);
                                                    });
                                            } else {
                                                setswapspinner(false);
                                                toastError("Transaction Canceled!");
                                            }
                                        })
                                        .catch((error) => {
                                            setswapspinner(false);
                                            toastError("Transaction Canceled!");
                                        });
                                } else {
                                    setswapspinner(false);
                                    toastError("Transaction Canceled!");
                                }
                            })
                            .catch((error) => {
                                setswapspinner(false);
                                toastError("Transaction Canceled!");
                            });
                    } else {
                        setswapspinner(false);
                        toastError("Whitelist Conversion limit exceeded!");
                    }
                } else {
                    setswapspinner(false);
                    toastError("Insuficiant balance!");
                }
            }
        } catch (err) {
            setswapspinner(false);
            console.log("err", err);
            toastError("Something went wrong!");
        }
    };

    const checkwhitelist = async () => {
        if (setting && setting.swap_contract_abi && setting.swap_contract_address) {
            if (account) {
                let time = 0;
                let contract = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
                const oldwhite = await contract.methods
                    .checkWhitlist(account)
                    .call()
                    .then(async (res) => {
                        return res;
                    })
                    .catch((erro) => {
                        // toastError("Transaction Canceled!");
                    });
                const iswhite = await contract.methods
                    .whitelist(account)
                    .call()
                    .then(async (res) => {
                        return res;
                    })
                    .catch((erro) => {
                        // toastError("Transaction Canceled!");
                    });
                if (iswhite) {
                    const whitelisttimestemp = await contract.methods
                        .whitelistTimestamps(account)
                        .call()
                        .then(async (res) => {
                            return res;
                        })
                        .catch((erro) => {
                            // toastError("Transaction Canceled!");
                        });
                    const whitelistperiad = await contract.methods
                        .whitelistPeriod()
                        .call()
                        .then(async (res) => {
                            return res;
                        })
                        .catch((erro) => {
                            // toastError("Transaction Canceled!");
                        });
                    let currenttimestemp = new Date().getTime();
                    if ((Number(whitelisttimestemp) + Number(whitelistperiad)) * 1000 > currenttimestemp) {
                        settimestemp((Number(whitelisttimestemp) + Number(whitelistperiad)) * 1000 - currenttimestemp);
                        setisinwhitelist(false);
                        setdisptimer(true);
                        setswaptimerspinner(false);
                    } else {
                        setisinwhitelist(true);
                        setdisptimer(false);
                        settimestemp(0);
                        sethour(0);
                        setminute(0);
                        setsecond(0);
                        setswaptimerspinner(false);
                    }
                } else {
                    if (oldwhite) {
                        setisinwhitelist(true);
                        setdisptimer(false);
                        setswaptimerspinner(false);
                    } else {
                        setisinwhitelist(false);
                        setdisptimer(false);
                        setswaptimerspinner(false);
                    }
                }
            }
        }
    };

    const getTimer = () => {
        sethour(Math.floor(timestemp / (1000 * 60 * 60)));
        setminute(Math.floor((timestemp / 1000 / 60) % 60));
        setsecond(Math.floor((timestemp / 1000) % 60));
        settimestemp((prev) => prev - 1000);
    };
    const getTimer2 = () => {
        sethour2(Math.floor(timestemp2 / (1000 * 60 * 60)));
        setminute2(Math.floor((timestemp2 / 1000 / 60) % 60));
        setsecond2(Math.floor((timestemp2 / 1000) % 60));
        settimestemp2((prev) => prev - 1000);
    };

    useEffect(() => {
        let interval;
        if (timestemp > 0) {
            interval = setInterval(() => getTimer(), 1000);
        } else {
            clearInterval(interval);
            if (timestemp !== null) {
                settimer(false);
                setisinwhitelist(true);
            }
        }

        return () => clearInterval(interval);
    }, [timestemp]);

    useEffect(() => {
        let interval;
        if (timestemp2 > 0) {
            interval = setInterval(() => getTimer2(), 1000);
        } else {
            clearInterval(interval);
            if (timestemp2 !== null) {
                // settimer(false);
                // setisinwhitelist(true);
                setislimitexided(false);
            }
        }

        return () => clearInterval(interval);
    }, [timestemp2]);

    const start24HourTimer = async () => {
        if (setting && setting.swap_contract_abi && setting.swap_contract_address) {
            let contract = new library.eth.Contract(JSON.parse(setting.swap_contract_abi), setting.swap_contract_address);
            const endtime = await contract.methods
                .conversionTimestamp(account)
                .call()
                .then(async (res) => {
                    return res;
                })
                .catch((erro) => {
                    // toastError("Transaction Canceled!");
                });
            let currenttimestemp = new Date().getTime();
            if (Number(endtime) > 0) {
                settimestemp2(Number(endtime) * 1000 - currenttimestemp);
                setislimitexided(true);
            } else {
                setislimitexided(false);
            }
        }
    };

    useEffect(() => {
        if (account && setting) {
            start24HourTimer();
            getBalance();
            getWhitelistlimit();
            checkwhitelist();
            getTotalswap();
            getSwapData();
        }
        if (setting.swap_message && setting.swap_message) {
            setSwapMaintenanceMsg(setting.swap_message);
        } else {
            setSwapMaintenanceMsg("");
        }
    }, [account, setting]);

    return (
        <>
            <section
                className="services-one about-page-services pt-0 swap_background"
                // id="swap2"
                style={{ height: "800px", overflow: "hidden", backgroundImage: 'url("assets/img/home-2/fact/leader-board-bg.png")' }}
            >
                <div className="container mt-5">
                    <div className="row text-center">
                        <h2 className="title f-500">Swap OXY</h2>
                    </div>
                    <div className="col-xl-12 col-lg-12 pb-5">
                        <div className="services-one__sqingle wow fadeInUp " data-wow-delay="300ms" style={{ marginTop: "10px" }}>
                            <form className="subscribe__form text-left mt-5">
                                {setting && (setting.swap_status === "false" || !setting.swap_status) ? (
                                    account ? (
                                        swaptimerspinner ? (
                                            account ? (
                                                <div className="card swap-main p-2 w-100">
                                                    <div className="card-body">
                                                        <div className="row text-left d-flex justify-content-center">
                                                            <div className="text-center">
                                                                <img width={120} height={120} src="/assets/img/Spin-1s-200px.gif" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="card swap-main p-2 w-100">
                                                    <div className="card-body">
                                                        <div className="row text-left d-flex justify-content-center">
                                                            <div className="text-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-base mt-3"
                                                                    style={{ padding: "0px 63px" }}
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModalCenter"
                                                                >
                                                                    Connect
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ) : isinwhitelist ? (
                                            islimitexided ? (
                                                <div className="card swap-main p-2 w-100">
                                                    <div className="card-body">
                                                        <div className="row text-left">
                                                            <div>
                                                                <div className="tab-content" id="pills-tabContent">
                                                                    <div
                                                                        className="tab-pane fade show active d-flex flex-column justify-content-center align-items-center"
                                                                        id="pills-home"
                                                                        role="tabpanel"
                                                                        aria-labelledby="pills-home-tab"
                                                                    >
                                                                        {/* <h4 className="title f-500 m-0">Your adress is in whitelist period.</h4> */}
                                                                        {/* <br /> */}
                                                                        <h2 className="title f-500 m-0">{hour2 + ":" + minute2 + ":" + second2}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="card swap-main p-2 w-100">
                                                    <div className="card-body">
                                                        <div className="text-center">
                                                            <b>Address</b>
                                                            <br />
                                                            {account}
                                                            <br />
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="mx-2">
                                                                    <b>Today Swap</b>
                                                                    <br />
                                                                    {totalswap}&nbsp;{process.env.REACT_APP_TICKER}
                                                                </div>
                                                                <div className="mx-2">
                                                                    <b>Remaining</b>
                                                                    <br />
                                                                    {remaining}&nbsp;{process.env.REACT_APP_TICKER}
                                                                </div>
                                                                <div className="mx-2">
                                                                    <b>Daily Remaining</b>
                                                                    <br />
                                                                    {dailyRemaining}&nbsp;{process.env.REACT_APP_TICKER}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div>
                                                                <div className="tab-content" id="pills-tabContent">
                                                                    <div
                                                                        className="tab-pane fade show active"
                                                                        id="pills-home"
                                                                        role="tabpanel"
                                                                        aria-labelledby="pills-home-tab"
                                                                    >
                                                                        {" "}
                                                                        <div className="row">
                                                                            <div className="swap-in-container">
                                                                                <div className="row">
                                                                                    <div className="col-lg-6 col-md-6 col-12 mt-2">
                                                                                        <label>From</label>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6 col-12 text-center mt-2">
                                                                                        <label>
                                                                                            Balance : {bln ? bln.toFixed(4) : 0}{" "}
                                                                                            {process.env.REACT_APP_TICKER}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="swap-input-line">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="swap-input"
                                                                                        style={{ color: "#fff", backgroundColor: "transparent" }}
                                                                                        value={swapvalue}
                                                                                        onChange={(e) => changeSwapValue(e.target.value)}
                                                                                    />
                                                                                    <button
                                                                                        type="button"
                                                                                        className="swap-input-btn w-25 bg-light text-dark mx-1"
                                                                                        onClick={max}
                                                                                    >
                                                                                        Max
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            {swaphash ? (
                                                                                <>
                                                                                    <div
                                                                                        className="card mt-2 mb-2"
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                            backgroundColor: "transparent",
                                                                                            border: "1px solid transparent",
                                                                                        }}
                                                                                    >
                                                                                        <button
                                                                                            className="swap-btn"
                                                                                            type="button"
                                                                                            style={{ height: "auto", width: "auto" }}
                                                                                        >
                                                                                            <i className="fas fa-arrow-down"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="swap-in-container mt-2">
                                                                                        <div className="row">
                                                                                            <div className="col-lg-12 col-md-12 col-12 mt-2 text-center">
                                                                                                <label>Received:</label>
                                                                                                <p>{dispswapvalue}</p>
                                                                                            </div>
                                                                                            <div className="col-lg-12 col-md-12 col-12 mt-2 text-center">
                                                                                                <label>Tnx Hash: </label>
                                                                                                <p>
                                                                                                    <a
                                                                                                        href={`${process.env.REACT_APP_EXPLORER}tx/${swaphash}`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {swaphash}
                                                                                                    </a>
                                                                                                    &nbsp;&nbsp;
                                                                                                    <a
                                                                                                        type="button"
                                                                                                        onClick={() => copyaddress(swaphash)}
                                                                                                    >
                                                                                                        <i
                                                                                                            type="button"
                                                                                                            className="fas fa-copy"
                                                                                                            title="click to copy address"
                                                                                                        ></i>
                                                                                                    </a>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            <div className="col-12 text-center">
                                                                                {account ? (
                                                                                    <>
                                                                                        {!hidebtn ? (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-base mt-3"
                                                                                                style={{ padding: "0px 63px" }}
                                                                                                onClick={swap}
                                                                                            >
                                                                                                Swap
                                                                                            </button>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-base mt-3"
                                                                                        style={{ padding: "0px 63px" }}
                                                                                        data-toggle="modal"
                                                                                        data-target="#exampleModalCenter"
                                                                                    >
                                                                                        Connect
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="card swap-main p-2 w-100">
                                                <div className="card-body">
                                                    <div className="row text-left">
                                                        <div>
                                                            {disptimer ? (
                                                                <div className="tab-content" id="pills-tabContent">
                                                                    <div
                                                                        className="tab-pane fade show active d-flex flex-column justify-content-center align-items-center"
                                                                        id="pills-home"
                                                                        role="tabpanel"
                                                                        aria-labelledby="pills-home-tab"
                                                                    >
                                                                        <h4 className="title f-500 m-0">Your adress is in whitelist period.</h4>
                                                                        <br />
                                                                        <h2 className="title f-500 m-0">{hour + ":" + minute + ":" + second}</h2>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="tab-content text-center" id="pills-tabContent">
                                                                    First you need to whitelist your address with <br />
                                                                    <h3>
                                                                        {whitelistlimit} {process.env.REACT_APP_TICKER}
                                                                    </h3>
                                                                    <div
                                                                        className="tab-pane fade show active d-flex justify-content-center mt-2"
                                                                        id="pills-home"
                                                                        role="tabpanel"
                                                                        aria-labelledby="pills-home-tab"
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-base"
                                                                            style={{ padding: "0px 63px" }}
                                                                            onClick={addInWhiteList}
                                                                        >
                                                                            Whitelist
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="card swap-main p-2 w-100">
                                            <div className="card-body">
                                                <div className="row text-left d-flex justify-content-center">
                                                    <div className="text-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-base mt-3"
                                                            style={{ padding: "0px 63px" }}
                                                            data-toggle="modal"
                                                            data-target="#exampleModalCenter"
                                                        >
                                                            Connect
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="card swap-main p-2 w-100">
                                        <div className="card-body">
                                            <div className="row text-left d-flex justify-content-center">
                                                <div className="text-center">{parse(swapMaintenanceMsg)}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
                <div
                    className={swapspinner ? "modal fade show" : "modal fade"}
                    id="swap-loading"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    style={swapspinner ? { display: "block" } : { display: "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="connectWalletPop1">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 style={{ color: "white" }}>Please wait...</h4>
                                </div>
                                <div className="modal-body text-center">
                                    <div className="details">
                                        <img width={150} height={150} src="/assets/img/Spin-1s-200px.gif" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* </section> */}
        </>
    );
}

export default Swap2;
