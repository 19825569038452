import React, { useState, useEffect, useContext } from 'react';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import './pagination/style.css';
import axios from 'axios';
import { Oxyo2 } from '../Oxyo2';
const OverviewTeam = (props) => {
    document.title = `OverviewTeam - ${process.env.REACT_APP_APP_NAME}`
    const { copyaddress, toastInfo } = useContext(Oxyo2)
    // console.log("staking",props);

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total64, settotal64] = useState(0);
    const [total60, settotal60] = useState(0);
    const [total40, settotal40] = useState(0);
    const [dataaddress, setdataaddress] = useState(null);
    // const [reffof, setreffof] = useState(0);

    const onGetAllRecord = async (address, level) => {
        // console.log("reerlr", address);
        if (address) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "teambusiness", address: address, level: level })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data);
                    setstaking(res.data.arrysent)
                    settotal64(res.data.total64.finalTotal)
                    settotal60(res.data.total64.fortyPer)
                    settotal40(res.data.total64.sixtyPer)
                    setLoading(false);

                })
        }
    }

    const getdata = (address, count) => {
        if (count !== 0) {
            setdataaddress(address);
            onGetAllRecord(address, 0);
        } else {
            toastInfo('User has no members');
        }
    }

    useEffect(() => {
        setTimeout(() => {
            // console.log("settimeout dasdasdasdasdasdasd");
            onGetAllRecord(props.currentAccount, 0);
        }, 2000);
    }, [props.currentAccount])
    const columns = [
        {
            name: "sr",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: 100
        },
        {
            name: "Position",
            selector: "position",
            cell: (data) => <span >{data.position ? data.position : '-'}</span>,
            key: "position",
            width: 100
        },
        {
            name: "address",
            selector: "address",
            cell: (data) => <span onClick={() => copyaddress(data.faddress)} >{data.address} <i className='far fa-copy' type="button" ></i></span>,
            key: "address",
            width: "300px"
        },
        {
            name: "reff_address",
            selector: "reff_address",
            cell: (data) => <span onClick={() => copyaddress(data.freff_address)} >{data.reff_address} <i className='far fa-copy' type="button" ></i></span>,
            key: "reff_address",
            width: "300px"
        },
        {
            name: "Staking",
            selector: "tstaking",
            cell: (data) => <span >$ {data.tstaking}</span>,
            key: "tstaking",
            width: "150px"
        },
        {
            name: "Team Business($)",
            selector: "teambusiness",
            cell: (data) => <span >$ {data.teambusiness}</span>,
            key: "teambusiness",
            width: "150px"
        },
        {
            name: "Total Member ",
            selector: "teamcount",
            key: "teamcount",
            width: "150px"
        },

        {
            name: "Activation ",
            selector: "activedatetime",
            key: "activedatetime",
            width: "200px"
        },
        {
            name: "Joining",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        },
        {
            name: "Detail",
            cell: (data) => <button className='btn btn-primary btn-sm btn-base-2 ' onClick={() => getdata(data.faddress, data.teamcount)} style={{ height: '35px', lineHeight: '35px', padding: '0 11px' }} id={data.faddress}>Details</button>,
            width: 100
        }


    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div className="col-lg-12">
                <div className="td-sidebar" >
                    <div className="table-responsive text-nowrap tb">
                        <div className="widget widget_add">
                            <div className='justify-content-between stakingliner d-lg-flex d-md-flex p-0'>
                                <h3 className="widget-title border-0 text-left p-0" >Team Business
                                    {dataaddress ?
                                        <><h6>showing data of {dataaddress} </h6></>
                                        : ''}
                                </h3>
                                <span>
                                    <select className="form-select form-select-lg mb-3" onChange={(e) => onGetAllRecord(props.currentAccount, Number(e.target.value))} aria-label=".form-select-lg example">
                                        <option selected value="0">1</option>
                                        <option value="1">2</option>
                                        <option value="2">3</option>
                                        <option value="3">4</option>
                                        <option value="4">5</option>
                                        <option value="5">6</option>
                                        <option value="6">7</option>
                                        <option value="7">8</option>
                                        <option value="8">9</option>
                                        <option value="9">10</option>
                                        <option value="10">11</option>
                                        <option value="11">12</option>
                                        <option value="12">13</option>
                                        <option value="13">14</option>
                                        <option value="14">15</option>
                                        <option value="15">16</option>
                                        <option value="16">17</option>
                                        <option value="17">18</option>
                                    </select>
                                </span>
                                <h3 className="widget-title border-0 text-left p-0" >Leadership: </h3>
                                <h3 className="widget-title border-0 text-left p-0" >40: $ {total40 ? total40.toFixed(4) : '0'} </h3>
                                <h3 className="widget-title border-0 text-left p-0" >60: $ {total60 ? total60.toFixed(4) : '0'} </h3>
                            </div>

                            <DataTableExtensions {...tableData}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    progressPending={loading}
                                    dense
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </>
    );
}

export default OverviewTeam;