import React, { useEffect, useState, useRef } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";

import { createContext } from "react";
import App from "./App";
import { injected, chainData, walletconnect, RPC } from "./Connectors/connectors";
import axios from "axios";
import copy from "copy-to-clipboard";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const Oxyo2 = createContext();

const OXYO2 = () => {
    var { active, account, balance, activate, deactivate, library, chainId, error } = useWeb3React();
    // var account = '0x143ec09a94a2e69e301338988b635b3df15e4fa0';
    // const [balance, setBalance] = useState(null);
    const [toggleMenu, setToggleMenu] = useState(false);
    const [urlReferral, seturlReferral] = useState(null);
    const [setting, setsetting] = useState([]);
    const [PACKEGES, setPACKEGES] = useState([]);
    const [userData, setuserData] = useState([]);
    const [userRegistered, setuserRegistered] = useState(true);
    const [ac, setac] = useState("");
    const [status, setstatus] = useState(false);
    const [curreffadd, setcurreffadd] = useState("");
    const [RATE, setRATE] = useState(0);
    const [LIVERATE, setLIVERATE] = useState(0);
    const [Balance, setBalance] = useState(0);
    const [DashD, setDashD] = useState([]);
    const [UDashD, setUDashD] = useState([]);

    const inputRef = useRef();

    function autofocusttextbox() {
        inputRef.current.focus();
    }

    const formatAddress = (address) => {
        return address.substr(0, 5) + "..." + address.substr(-5, 5);
    };
    const kFormatter = (num) => {
        return Math.abs(num) > 100000 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k" : Math.sign(num) * Math.abs(num);
    };
    const copyaddress = (address) => {
        copy(address);
        toastSuccess("Copied");
    };

    const toastSuccess = (data) => {
        toast.success(data, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };
    const toastError = (data) => {
        toast.error(data, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };
    const toastDismiss = (data) => {
        toast.dismiss();
    };
    const toastInfo = (data) => {
        toast(data, {
            // Custom Icon
            icon: "❕",
            // Aria
            ariaProps: {
                role: "status",
                "aria-live": "polite",
            },
        });
    };

    const getBalance = async () => {
        // console.log("das",!setting,!library);
        if (!library || !setting) {
            return;
        }
        var token = await new library.eth.Contract(JSON.parse(setting.token_abi), setting.token_address);
        var tokenbln = await token.methods.balanceOf(account).call();
        const accBalanceEth = library.utils.fromWei(tokenbln, "ether");
        // console.log("ss", accBalanceEth);
        setBalance(Number(accBalanceEth).toFixed(6));
        // const busl = await new library.eth.Contract(JSON.parse(setting.buy_sell_contract_abi), setting.buy_sell_contract_address);
        // const blof = await busl.methods.getLivePrice().call();
        // // console.log("asasasas",blof);
        // var hu = Number(library.utils.fromWei(blof, "ether"));
        // setRATE(hu.toFixed(4));
        // await axios
        //     .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        //         method: "getLiveRateAxios",
        //         key: process.env.REACT_APP_BACKEND_LINK,
        //     })
        //     .then((res) => {
        //         setRATE(res.data.rate);
        //     })
        //     .catch((err) => console.log("error : ", err));
    };

    const setweb3rate = async () => {
        if (!setting.buy_sell_contract_abi) {
            return
        }
            const web3 = new Web3(Web3.givenProvider || RPC);
            const busl = new web3.eth.Contract(JSON.parse(setting.buy_sell_contract_abi), setting.buy_sell_contract_address);
            const blof = await busl.methods.getLivePrice().call();
            // console.log("asasasas",blof);
            var hu = Number(web3.utils.fromWei(blof, "ether"));
            //var final = hu + Number(hu * setting.live_rate_increase / 100);
            setLIVERATE(hu.toFixed(4));
        // await axios
        //     .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        //         method: "getLiveRateAxios",
        //         key: process.env.REACT_APP_BACKEND_LINK,
        //     })
        //     .then((res) => {
        //         setRATE(res.data.rate);
        //         console.log("res.data.rate : ", res.data.rate);
        //     })
        //     .catch((err) => console.log("error : ", err));
    };

    const getSetting = async () => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                method: "getsetting",
                setting: 1,
                key: process.env.REACT_APP_BACKEND_LINK,
            })
            .then((res) => {
                // console.log("ada", res.data[0]);
                if (res.data.error) {
                    // toastError(res.data.error)
                    return;
                }
                setsetting(res.data[0]);
                if (res.data[0].live_rate_status) {
                    setRATE(res.data[0].live_rate_content)
                } else {
                    setRATE(res.data[0].live_rate)
                }
            });
    };

    const getDashAllstake = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "dashData" }).then((res) => {
            setDashD(res.data);
        });
    };

    const GetUDashD = async (account) => {
        if (account) {
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getDashD", fromaddress: account.toLowerCase() }).then((res) => {
                // console.log("Get ssssdetaails ", res.data);
                setUDashD(res.data);
                // console.log("dasdasdasdasdad",res.data);
            });
        }
    };

    const getPKGS = async () => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                method: "pkg",
                submethod: "get",
                key: process.env.REACT_APP_BACKEND_LINK,
            })
            .then((res) => {
                if (res.data.error) {
                    // toastError(res.data.error)
                    return;
                }
                setPACKEGES(res.data.data);
            });
    };

    const getUserData = async (account) => {
        if (!account) {
            return;
        }
        await axios.post(process.env.REACT_APP_BACKEND_LINK, { method: "getuser", address: account.toLowerCase() }).then((res) => {
            if (res.data.error) {
                setuserData([]);
                return;
            }
            if (res.data.length === 0) {
                setuserRegistered(false);
                setuserData([]);
            } else {
                setuserRegistered(true);
                setuserData(res.data[0]);
            }
        });
    };

    const connectMetamask = async () => {
        try {
            var connect = await activate(injected);
            localStorage.igtStaking = 1;
            localStorage.setItem("OawoISasSoi", "QsaejkqwAy");
            localStorage.setItem("FkRojtg", "WanGuawd");
            // toast('Metamask Connected Successfully...')
        } catch (error) {
            alert(error);
        }
    };
    const getUserbyAccountAddress = async () => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                method: "user",
                submethod: "getbyid",
                address: account,
                key: process.env.REACT_APP_BACKEND_LINK,
            })
            .then((res) => {
                // console.log("Account by user data---------: ", res.data)
                setac(res.data.data[0].address);
                setstatus(res.data.data[0].status);
                setcurreffadd(res.data.data[0].refferal_address);
            });
    };
    const changeNetwork = async () => {
        if (!window.ethereum) throw new Error("No crypto wallet found");
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainData[0].chainId }],
            });
        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            console.log("eoror", err);
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: chainData,
                });
            }
        }
    };

    const logout = () => {
        try {
            deactivate();
            localStorage.removeItem("OawoISasSoi");
            localStorage.removeItem("FkRojtg");
            // localStorage.setItem("isconnected", false)
            window.location.reload(true);
        } catch (error) {
            alert(error);
        }
    };
    var handleMenuClick = () => {
        setToggleMenu(!toggleMenu);
    };
    const connectWallet = async () => {
        try {
            var connect = await activate(walletconnect);
            // console.log("----->connect", connect)
            localStorage.igtStaking = 1;
            localStorage.setItem("OawoISasSoi", "QsaejkqwAy");
            localStorage.setItem("FkRojtg", "AkUuejkp");

            // window.location.reload(true);
        } catch (error) {
            alert(error);
        }
    };
    useEffect(() => {
        if (error instanceof UnsupportedChainIdError) {
            toastError("Connect with BSC Mainnet");
            changeNetwork();
        }
    }, [error]);

    useEffect(() => {
        //OawoISasSoi : connection
        //QsaejkqwAy : true
        //LopPowkd : false
        //FkRojtg : type
        //WanGuawd: metamask
        //AkUuejkp: wallet connect
        if (localStorage.getItem("OawoISasSoi") === "QsaejkqwAy") {
            if (localStorage.getItem("FkRojtg") === "WanGuawd") {
                connectMetamask();
            } else if (localStorage.getItem("FkRojtg") === "AkUuejkp") {
                connectWallet();
            }
        }
        getSetting();
        getDashAllstake();
        getPKGS();
    }, []);
    const OverViewSet = (account) => {
        getUserData(account);
        GetUDashD(account);
    };
    useEffect(() => {
        getUserData(account);
        GetUDashD(account);
    }, [account]);
    useEffect(() => {
        setInterval(function () {
            // console.log("sdjagd5 555");
            getBalance();
        }, 10000);
    }, [setting, library]);

    useEffect(() => {
        setweb3rate()
    }, [setting])
    // useEffect(() => {
    //     setweb3rate();
    // }, []);

    return (
        <Oxyo2.Provider
            value={{
                connectMetamask,
                balance,
                library,
                active,
                account: account ? account.toLowerCase() : account,
                formatAddress,
                copyaddress,
                logout,
                toastError,
                toastSuccess,
                toastInfo,
                handleMenuClick,
                toggleMenu,
                urlReferral,
                seturlReferral,
                inputRef,
                autofocusttextbox,
                setting,
                userData,
                getUserData,
                getUserbyAccountAddress,
                ac,
                status,
                curreffadd,
                connectWallet,
                PACKEGES,
                RATE,
                LIVERATE,
                Balance,
                toastDismiss,
                DashD,
                userRegistered,
                UDashD,
                OverViewSet,
                kFormatter,
            }}
        >
            <App />
            <ToastContainer />
        </Oxyo2.Provider>
    );
};

export default OXYO2;
