import React, { useState, useEffect, useContext } from 'react';
import Footer from './Footer';
import Header2 from './Header2';
import Dashmenu from './DashboardMenu'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import './pagination/style.css';
import axios from 'axios';
import "./styles.css";
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import { Oxyo2 } from '../Oxyo2';
const LeadershipReward = (props) => {
    const { account } = useContext(Oxyo2)

    document.title = `Leadership Reward - ${process.env.REACT_APP_APP_NAME}`
    const [staking, setstaking] = useState([]);
 
    const [loading, setLoading] = useState(false);
    // console.log("im from dashboard ", account);

    const onGetAllRecord = async () => {
        if (account) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "weekly", fromaddress: account })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data.sta);

                    setstaking(res.data)
                    setLoading(false);

                })
        }
    }

    useEffect(() => {
        onGetAllRecord();
    }, [account])
    const columns = [
        {
            name: "#",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "From",
            selector: "fromaddress",
            key: "fromaddress",
            width: "200px"
        },
        {
            name: "Staking Amt.",
            selector: "staking_amount",
            key: "staking_amount",
            width: "120px"
        },
        {
            name: "Reward($)",
            selector: "income",
            key: "income",
            width: "120px"
        },
        {
            name: "Reward(%)",
            selector: "income_per",
            key: "income_per",
            width: "120px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        }
    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>
       
            <Header2  />
            <div class="blog-area bg-blue pd-top-100 pd-bottom-100 dashboard_box_image">
                <div class="container" style={{ marginTop: "20px" }}>
                    <div class="fact-area bg-cover bg-overlay-2 text-center">
                        <div class="container">
                            <div class="row mb-5">
                                <Adrbox />
                                <Blcbox />
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="td-sidebar">
                                <Dashmenu />
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="td-sidebar" >
                                <div className="table-responsive text-nowrap tb">
                                    <div class="widget widget_add">
                                        <h3 class="widget-title style-white text-left stakingliner" >Leadership Reward</h3>

                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={true}
                                                pagination
                                                progressPending={loading}
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default LeadershipReward;