import React, { useState, useEffect, useContext } from 'react';
import Footer from './Footer';
import Header2 from './Header2';
import Dashmenu from './DashboardMenu';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import './pagination/style.css';
import axios from 'axios';
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import { Oxyo2 } from '../Oxyo2';

const BuySell = (props) => {
    const { account } = useContext(Oxyo2)

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const onGetAllRecord = async () => {
        if (account) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap", submethod: "swapaddress", address: account, pageSize: 1000 })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data);
                    setstaking(res.data.data)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onGetAllRecord();
    }, [account])
    const columns = [
        {
            name: "#",
            selector: "id",
            key: "id",
            width: "100px"
        },
        {
            name: "Buy/Sell",
            selector: "balance",
            cell: data => <><span className={`p-1 ${data['type'] === 0 ? 'bg-success text-white' : 'bg-danger text-white'}`}>{data["type"] === 0 ? 'Buy' : 'Sell'}</span></>,
            key: "balance",
            width: "100px"
        },
        {
            name: "Hash",
            selector: "hash",
            cell: data => <a target="_blank" href={`${process.env.REACT_APP_EXPLORER}tx/${data["hash"]}`}>{data["s_hash"]}</a>,
            key: "hash",
            width: "200px"
        },
        {
            name: process.env.REACT_APP_TICKER,
            selector: "amount_oxy",
            cell: data => <><span >{data["amount_oxy"] ? data["amount_oxy"].toFixed(4) : 0} {process.env.REACT_APP_TICKER}</span></>,
            key: "oxy",
            width: "180px"
        },
        {
            name: "BUSD",
            selector: "amount_busd",
            cell: data => <><span >{data["amount_busd"] ? data["amount_busd"].toFixed(4) : 0} BUSD</span></>,
            key: "busd",
            width: "180px"
        },
        {
            name: "Rate($)",
            selector: "rate",
            cell: data => <><span >$ {data["rate"]}</span></>,
            key: "rate",
            width: "180px"
        },

        {
            name: "Datetime",
            selector: "datetime",
            cell: data => <><span>{data["datetime"]}</span></>,
            key: "datetime",
            width: "160px"
        }
        // {
        //     name: "Status",
        //     selector: "status",
        //     key: "status",
        //     width: "200px"
        // }

    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>
            <Header2 />

            <div class="blog-area bg-blue pd-top-100 pd-bottom-100 dashboard_box_image">
                <div class="container" style={{ marginTop: "20px" }}>
                    <div class="fact-area bg-cover bg-overlay-2 text-center pd-bottom-70">
                        <div class="container">
                            <div class="row mb-5">
                                <Adrbox />
                                <Blcbox />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="td-sidebar">
                                <Dashmenu />
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="td-sidebar" >
                                <div className="table-responsive text-nowrap tb">
                                    <div class="widget widget_add">
                                        <h3 class="widget-title style-white text-left stakingliner" >Buy/Sell</h3>
                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={true}
                                                pagination
                                                progressPending={loading}
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BuySell;