import React, { useState, useEffect, useContext } from 'react';
import Footer from './Footer';
import Header2 from './Header2';
import Dashmenu from './DashboardMenu';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import './pagination/style.css';
import axios from 'axios';
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import { Oxyo2 } from '../Oxyo2';

const FlushedIncome = () => {
    const { account } = useContext(Oxyo2)
    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);

    const onGetAllRecord = async () => {
        if (account) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "flushedstatement", address: account })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data.sta);

                    setstaking(res.data)
                    setLoading(false);

                })
        }
    }
    useEffect(() => {
        onGetAllRecord();
    }, [account])
    const columns = [
        {
            name: "#",
            selector: "sr",
            key: "sr",
            width: "100px"
        },
        {
            name: "flushed",
            selector: "flushed",
            cell: (data) => <strong className='text-danger text-uppercase' >{data.flushed}</strong>,
            key: "flushed",
            width: "150px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        },

        {
            name: "Type",
            selector: "description",
            key: "description",
            width: "350px"
        },
        // {
        //     name: "Credit($)",
        //     selector: "credit",
        //     key: "credit",
        //     width: "180px"
        // },
        {
            name: "amount($)",
            selector: "amount",
            key: "amount",
            width: "180px"
        },
        // {
        //     name: "Balance($)",
        //     selector: "balance",
        //     key: "balance",
        //     width: "150px"
        // },
        
        // {
            //     name: "Status",
            //     selector: "status",
            //     key: "status",
            //     width: "200px"
            // }
            
        ];
        const data = [
            ...staking
        ];
        const tableData = {
            columns,
            data
    };
    return (
        <>
            <Header2 />

            <div class="blog-area bg-blue pd-top-100 pd-bottom-100 dashboard_box_image">
                <div class="container" style={{ marginTop: "20px" }}>
                    <div class="fact-area bg-cover bg-overlay-2 text-center pd-bottom-70">
                        <div class="container">
                            <div class="row mb-5">
                                <Adrbox />
                                <Blcbox />
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="td-sidebar">
                                <Dashmenu />
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="td-sidebar" >
                                <div className="table-responsive text-nowrap tb">
                                    <div class="widget widget_add">
                                        <h3 class="widget-title style-white text-left stakingliner" >All Flushed Income</h3>
                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={true}
                                                pagination
                                                progressPending={loading}
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    );
}

export default FlushedIncome;