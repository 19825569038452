import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';
const Pagination = props => {
 
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  var numkey = 0;
  return (
    <>
      <div className="col-12">
        <nav className="text-center">
          <ul className={classnames('pagination-container', { [className]: className })}>
            <li key={numkey++} className={classnames('pagination-item', {
              disabled: currentPage === 1
            })}
              onClick={onPrevious}><i className="fas fa-arrow-circle-left"></i></li>
            {paginationRange.map(pageNumber => {
              if (pageNumber === DOTS) {
                return <li key={numkey++} class="pagination-item dots">&#8230;</li>;
              }
              return (
                <li key={numkey++} className={classnames('pagination-item', {
                  selected: pageNumber === currentPage
                })} onClick={() => onPageChange(pageNumber)}>{pageNumber}</li>
              );
            })}
            <li key={numkey++} className={classnames('pagination-item', {
              disabled: currentPage === lastPage
            })}
              onClick={onNext}><i className="fas fa-arrow-circle-right"></i></li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Pagination;
