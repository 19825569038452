import React, { useState, useEffect } from 'react';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import './pagination/style.css';
import axios from 'axios';
const OverviewStakings = (props) => {
    document.title = `OverviewStakings - ${process.env.REACT_APP_APP_NAME}`
    // console.log("staking",props);

    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);

    const onGetAllRecord = async () => {
        if (props.currentAccount) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "staking", fromaddress: props.currentAccount })
                .then(res => {
                    setstaking(res.data)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onGetAllRecord();
    }, [props.currentAccount])
    const columns = [

        {
            name: "Hash#",
            selector: "hash",
            cell: (data) => <a id={data.hash} href={`${process.env.REACT_APP_EXPLORER}tx/${data.fhash}`}>{data.hash}</a>,
            key: "hash",
            width: "200px"
        },
        {
            name: "F.Add",
            selector: "fromaddress",
            key: "fromaddress",
            width: "200px"
        },
        {
            name: "Invest($)",
            selector: "total_investment",
            key: "total_investment",
            width: 100
        },

        {
            name: "Token Rate ",
            selector: "token_rate",
            key: "token_rate",
            width: 100
        },

        {
            name: "Tokens",
            selector: "total_token",
            width: 100
        },

        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        },
        {
            name: "End Datetime",
            selector: "end_datetime",
            key: "end_datetime",
            width: "200px"
        }
        ,
        // {
        //   name: "Status",
        //   selector: "status",
        //   key: "status",
        //   width: 100
        // },
        {
            name: "Type",
            selector: "t_type",
            key: "t_type",
            width: 100
        }
    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div class="col-lg-12">
                <div class="td-sidebar" >
                    <div className="table-responsive text-nowrap tb">
                        <div class="widget widget_add">
                            <h3 class="widget-title style-white text-left stakingliner" >Stakings</h3>

                            <DataTableExtensions {...tableData} responsive={true}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    progressPending={loading}
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination

                                    highlightOnHover
                                    dense
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </>
    );
}

export default OverviewStakings;