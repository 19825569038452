import React, { useContext } from 'react';
import { Oxyo2 } from '../Oxyo2';
const TotalBox = (props) => {
    const { userData, RATE, UDashD } = useContext(Oxyo2)
    return (
        <>
            <div className="row justify-content-center">
                {props.isB ?
                    <div className="col-md-4 col-sm-4">
                        <div className="single-fact-inner default-card">
                            <div className="default-card-inner"></div>
                            <div className="thumb">
                                <img src="assets/img/fact/1.png" alt="img" />
                            </div>
                            <div className="details">
                                <h3>$ {props.balance ? props.balance.toFixed(4) : '0'}</h3>
                                <p>Wallet Balance</p>
                            </div>
                        </div>
                    </div>
                    : ''}
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/1.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.total_staking ? UDashD.total_staking.toFixed(4) : '0'}</h3>
                            <p>Total Staked $</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/1.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>{UDashD.total_token ? UDashD.total_token.toFixed(2) : 0}  {process.env.REACT_APP_TICKER}</h3>
                            <p>Total Staked ({process.env.REACT_APP_TICKER})</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/1.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>{UDashD.total_token ? UDashD.total_token.toFixed(2) : 0}  {process.env.REACT_APP_TICKER}</h3>
                            <p>Total Burned ({process.env.REACT_APP_TICKER})</p>
                        </div>
                    </div>
                </div>
                {userData.bonus_income || UDashD.bonus_reward ?
                    <>
                        <div className="col-md-4 col-sm-4">
                            <div className="single-fact-inner default-card default-card-active">
                                <div className="default-card-inner"></div>
                                <div className="thumb">
                                    <img src="assets/img/fact/2.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h3> {userData.bonus_income ? "Activated" : 'Deactivate'} {userData.bonus_assigned_per ? userData.bonus_assigned_per : '0'} % </h3>
                                    <p>Super Bonus</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="single-fact-inner default-card default-card-active">
                                <div className="default-card-inner"></div>
                                <div className="thumb">
                                    <img src="assets/img/fact/2.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h3>$ {userData.dollar_locked ? userData.dollar_locked.toFixed(4) : '0'} </h3>
                                    <p>Dollar Locked</p>
                                </div>
                            </div>
                        </div></>
                    : ''}
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/2.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.total_withdrawal ? UDashD.total_withdrawal.toFixed(4) : '0'}  </h3>
                            <p>Total Withdrawal</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/1.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>{UDashD.countreff ? UDashD.countreff : '0'}  </h3>
                            <p>Total Referral</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/3.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.total_referral_p ? UDashD.total_referral_p.toFixed(4) : '0'} </h3>
                            <p>Pending Referral</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/1.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.total_weekly ? UDashD.total_weekly.toFixed(4) : '0'} </h3>
                            <p>Leadership Reward</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/1.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.total_referral ? UDashD.total_referral.toFixed(4) : '0'}  </h3>
                            <p>Referral Reward</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/2.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.staking_reward ? UDashD.staking_reward.toFixed(4) : "0"} </h3>
                            <p>Staking Reward</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/2.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.bonus_reward ? UDashD.bonus_reward.toFixed(4) : "0"} </h3>
                            <p>Super Bonus Reward</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/2.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.staking_reward || UDashD.total_referral || UDashD.total_weekly ? (UDashD.staking_reward + UDashD.total_referral + UDashD.total_weekly).toFixed(4) : "0"} </h3>
                            <p>Total Earned </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/2.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {UDashD.round_income ? UDashD.round_income.toFixed(4) : "0"} </h3>
                            <p>Current Round Income</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/2.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {userData.team_business ? userData.team_business.toFixed(4) : "0"} </h3>
                            <p>Team Business</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="single-fact-inner default-card default-card-active">
                        <div className="default-card-inner"></div>
                        <div className="thumb">
                            <img src="assets/img/fact/2.png" alt="img" />
                        </div>
                        <div className="details">
                            <h3>$ {userData.flushed_income ? userData.flushed_income.toFixed(4) : "0"} </h3>
                            <p>Flushed Income</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TotalBox;