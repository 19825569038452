import React, { useState, useEffect, useContext } from 'react';
import Footer from './Footer';
import Header2 from './Header2';
import Dashmenu from './DashboardMenu';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import './pagination/style.css';
import axios from 'axios';
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import { Oxyo2 } from '../Oxyo2';

const withdrawalList = (props) => {
    const { account } = useContext(Oxyo2)

    document.title = `Withdrawal list - ${process.env.REACT_APP_APP_NAME}`
    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);
    // console.log("im from dashboard ", account);

    const onGetAllRecord = async () => {
        if (account) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "withdrawal", fromaddress: account })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data.sta);
                    setstaking(res.data)
                    setLoading(false);

                })
        }
    }

    useEffect(() => {
        onGetAllRecord();
    }, [account])
    const columns = [
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: "82px"
        },
        {
            name: "Hash",
            selector: "hash",
            cell: (data) => <a target="_blank" href={`${process.env.REACT_APP_EXPLORER}tx/${data.fhash}`}>{data.hash}</a>,
            key: "hash",
            width: "200px"
        },
        {
            name: "Total",
            selector: "total",
            key: "total",
            width: 100
        },

        {
            name: "Rate",
            selector: "rate",
            key: "rate",
            width: 100
        },
        {
            name: "Fee($)",
            selector: "fee_dollar",
            key: "fee_dollar",
            width: 100
        },

        {
            name: "Fee(token)",
            selector: "fee_token",
            key: "fee_token",
            width: 100
        },

        {
            name: "Burn(%)",
            selector: "burn_per",
            key: "burn_per",
            width: 100
        },
        {
            name: "Burn($)",
            selector: "total_burn_dollar",
            key: "total_burn_dollar",
            width: 100
        },
        {
            name: "Burn(Token)",
            selector: "total_burn_token",
            key: "total_burn_token",
            width: "160px"
        },
        {
            name: "Recieved",
            selector: "total_receive",
            key: "total_receive",
            cell: (data) => <span>{data.total_receive.toFixed(2)}</span>,
            width: "100px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "250px"
        }

    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>
            <Header2 />

            <div class="blog-area bg-blue pd-top-100 pd-bottom-100 dashboard_box_image">
                <div class="container" style={{ marginTop: "20px" }}>
                    <div class="fact-area bg-cover bg-overlay-2 text-center pd-bottom-70">
                        <div class="container">
                            <div class="row mb-5">
                                <Adrbox />
                                <Blcbox />
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="td-sidebar">
                                <Dashmenu />
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="td-sidebar" >
                                <div className="table-responsive text-nowrap tb">
                                    <div class="widget widget_add">
                                        <h3 class="widget-title style-white text-left stakingliner" >Withdrawal List</h3>

                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={true}
                                                pagination
                                                progressPending={loading}
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    );
}

export default withdrawalList;