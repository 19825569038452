import React from 'react'

import Home from './Components/Home';

// import AboutUs from './Components/AboutUs';
// import ContactUs from './Components/ContactUs';
// import Plan from './Components/Plan';
// import Tournaments from './Components/Tournaments';
// import SignInRegister from './Components/SignInRegister';
// import BlogPage from './Components/BlogPage';

import Stakings from './Components/Stakings';
import StakingsReward from './Components/StakingsReward';

import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
// import BlogPageDetails from './Components/BlogPageDetails';
import Dashboard from './Components/Dashboard';
import LeadershipReward from './Components/LeadershipReward';
import ReferralReward from './Components/ReferralReward';
import WithdrawalList from './Components/withdrawalList';
import Withdrawal from './Components/Withdraw';

import AcOverview from './Components/AcOverview';
import TeamBusiness from './Components/TeamBussiness';
import Statement from './Components/statement';
import UsersDate from './Components/UsersDate';
import BuySell from './Components/BuySell';
import LeadershipHistory from './Components/LeadershipHistory';
import FlushedIncome from './Components/FlushedIncome';
import BonusIncome from './Components/BonusIncome';
import SwapList from "./Components/SwapList";

const App = (props) => {

  return (
    <>
      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/:referrral" element={<Home />} />

          <Route exact path="/Withdraw" element={<Withdrawal />} />

          {/*  <Route exact path="/about" element={<AboutUs />} />
              <Route exact path="/contact" element={<ContactUs />} />
              <Route exact path="/plan" element={<Plan />} />
              <Route exact path="/tournaments" element={<Tournaments />} />
              <Route exact path="/signin-register" element={<SignInRegister />} />
              <Route exact path="/blog" element={<BlogPage />} />
              <Route exact path="/blog-details" element={<BlogPageDetails />} /> 
         */}
          <Route exact path="/stakings" element={<Stakings />} />

          <Route exact path="/stakingReward" element={<StakingsReward />} />

          <Route exact path="/LeadershipReward" element={<LeadershipReward />} />
          <Route exact path="/dashboard" element={<Dashboard />} />

          <Route exact path="/ReferralReward" element={<ReferralReward />} />

          {/* <Route exact path="/restake" element={<Restake TokenRate={theliverate} onLogout={onLogout} currentAccount={currentAccount} Setting={Setting} balance={balance} onLoginHandler={onLoginHandler} setRange={setRange} Range={Range} isConnected={isConnected} />} /> */}

          <Route exact path="/WithdrawalList" element={<WithdrawalList />} />
          <Route exact path="/TeamBusiness" element={<TeamBusiness />} />
          {/* <Route exact path="/account-overview" element={<AcOverview TokenRate={theliverate} onLogout={onLogout} currentAccount={currentAccount} Setting={Setting} balance={balance} onLoginHandler={onLoginHandler} setRange={setRange} Range={Range} isConnected={isConnected} />} /> */}
          <Route exact path="/checkover" element={<AcOverview />} />
          {/* <Route exact path="/account-overviwe-submit" element={<AccountOverviewSubmission />} /> */}
          {/* <Route exact path="/demo" element={<Demo />} /> */}
          <Route exact path="/Statement" element={<Statement />} />
          <Route exact path="/BonusIncome" element={<BonusIncome />} />
          <Route exact path="/UsersDate" element={<UsersDate />} />

          <Route exact path="/BuySell" element={<BuySell />} />
          <Route exact path="/LeadershipHistory" element={<LeadershipHistory />} />
          <Route exact path="/FlushedIncome" element={<FlushedIncome />} />
          <Route exact path="/SwapList" element={<SwapList />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;