import axios from "axios";
import React, { useState, useEffect } from "react";
import SocialMedia from './SocialMedia';
import parse from 'html-react-parser';
function Faq() {
    const [faq, setfaq] = useState('');
    const onGet = async (e) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "faq", submethod: "get" })
            .then(res => {
                setfaq(res.data)
            })
    }
    useEffect(() => {
        onGet();
    }, [])
    return (
        <>
            <div className="process-area border-bottom bg-blue text-center pd-bottom-50" style={{ paddingTop: "130px" }} id="faqs">
                <div className="container" style={{ marginBottom: "150px" }}>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title style-white text-center mb-20">
                                <h2 className="title">Frequently asked Questions</h2>
                            </div>
                        </div>
                    </div>

                    {faq.length && faq.map((item, key) => {
                        if (item.title === '') {
                            return ('')
                        } else {
                            return (
                                <div className='faq'>
                                    <input id={`faq-a${item._id}`} type='checkbox' />
                                    <label htmlFor={`faq-a${item._id}`}>
                                        <p className="faq-heading">{item.title}</p>
                                        <div className='faq-arrow'></div>
                                        <ul style={{ paddingLeft: "50px", margin: "30px 0px 30px 0px" }}>
                                            <li key={item._id} className="faq-text">{parse(item.content) }</li>
                                        </ul>
                                    </label>
                                </div>
                            )
                        }
                    })}
                    <SocialMedia />

                </div>
            </div>
        </>
    )
}

export default Faq;