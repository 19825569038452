
import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';
import Footer from './Footer';
import Header2 from './Header2';
import Dashmenu from './DashboardMenu'
import Pagination from './pagination/Pagination';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import './styles.css';
import Clock from './timer/Clock';
import axios from 'axios';
import SimpleDateFormat from "@riversun/simple-date-format";
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import TotalBox from './DashboardTotalBox';
import parse from 'html-react-parser';
import { Oxyo2 } from '../Oxyo2';

let PageSize = 4;
const Dashboard = (props) => {
    const { account, setting, userData } = useContext(Oxyo2)
    document.title = `Dashboard - ${process.env.REACT_APP_APP_NAME}`
    // console.log("im from dashboard", localStorage.getItem('wallet'));

    // const [deadline, setDeadline] = useState('December 25, 2022')
    const [currentPage, setCurrentPage] = useState(1);

    const [popupcontent, setpopupcontent] = useState('');
    const [popuptitle, setpopuptitle] = useState('');
    const [leveltranp, setleveltranp] = useState([]);
    const [Record, setRecord] = useState([]);
    const [ntfRestake, setntfRestake] = useState('');
    const [ntfRestakeHeader, setntfRestakeHeader] = useState('');
    const [withdrawdata, setwithdrawdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const restakePopup = useRef();
    // console.log("im from dashboard ", account);
 
    const html = setting.claimstake_message ? setting.claimstake_message : '<span></span>';
    const titlehtml = popuptitle ? popuptitle : '<span></span>';
    const popupcontenthtml = popupcontent ? popupcontent : '<span></span>';

    const setNotice = async () => {
        if (!userData) {
            return
        }
        var d = new Date(userData.restake_date);
        d.setDate(d.getDate() + 3);

        var td = new Date();
        var yr = td.getFullYear();
        var mn = td.getMonth() + 1;
        var dy = td.getDate();


        var yr1 = d.getFullYear();
        var mn1 = d.getMonth() + 1;
        var dy1 = d.getDate();
        var cdt = mn + "-" + dy + "-" + yr;
        var cdt2 = mn1 + "-" + dy1 + "-" + yr1;
        if (userData.restaking) {
            if (userData.blocked) { 
                setntfRestakeHeader("Notes") 
                setntfRestake("Your account is blocked.") 
                restakePopup.current.click(); 
            } else {
                if (new Date(cdt2) >= new Date(cdt)) {
                    setntfRestakeHeader("Notes")
                    setntfRestake("Your staking income reached to 4x. You are on gracing period. Kindly reactivate your staking contract by re-staking to get all your future incomes.")
                    restakePopup.current.click();
                    console.log("falseees");
                } else {
                    setntfRestakeHeader("Notes")
                    setntfRestake("Your id reached to 4x and your gracing period over. Now you will loose your staking, Referral & Leadership Reward also you can not take withdrawal. Please restake to activate your id.")
                    restakePopup.current.click();
                    console.log("afafs");
                }
            }
        } else {
            setntfRestake("")
            // setrestaking(iterator.restaking);
        }

    }
    const onGetRecord = async () => {
        if (account) {
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getStack", fromaddress: account })
                .then(res => {
                    // console.log("Setting", res.data);
                    setRecord(res.data);
                })
        }
    }

    const onGetAllRecord = async () => {
        if (account) {
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "levelp", fromaddress: account })
                .then(res => {
                    // console.log("Get Dashboard details ", res.data);
                    setleveltranp(res.data)
                })
        }
    }

    const onGetPwith = async (e) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "onGetAllRecord", submethod: "penwithdrawal", fromaddress: account })
            .then(res => {
                console.log("erers", res.data, withdrawdata.length);
                setwithdrawdata(res.data)
                setLoading(false)
            })
    }
    useEffect(() => {
        if (setting.dashboard_popup) {
            window.$('#exampleModalCenter1').modal('show');
            setpopupcontent(setting.dashboard_popup_content)
            setpopuptitle(setting.dashboard_popup_title)
        }
        onGetRecord();
        onGetAllRecord();
        onGetPwith()
    }, [account])
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return Record.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, props]);
    // console.log("dashboard ",DashData);
    const columns = [
        {
            name: "F.Add",
            selector: "fromaddress",
            key: "fromaddress",
            width: 100
        },
        {
            name: "Reward($)",
            selector: "income",
            key: "income",
            width: 100
        },
        {
            name: "Income Level ",
            selector: "income_level",
            key: "income_level",
            width: 100
        },
        {
            name: "Round",
            selector: "round",
            key: "round",
            width: 100
        },
        {
            name: "Reward(%)",
            selector: "income_per",
            key: "income_per",
            width: 100
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: 100
        },
        {
            name: "Status",
            selector: "status",
            key: "status",
            width: 100
        }
    ];
    const data = [
        ...leveltranp
    ];
    const tableData = {
        columns,
        data
    };
    const columnspen = [
        {
            name: "Total",
            selector: "total",
            key: "total",
            width: 100
        },

        {
            name: "Rate",
            selector: "rate",
            key: "rate",
            width: 100
        },
        {
            name: "Fee($)",
            selector: "fee_dollar",
            key: "fee_dollar",
            width: 100
        },

        {
            name: "Fee(token)",
            selector: "fee_token",
            key: "fee_token",
            width: 100
        },

        {
            name: "Burn(%)",
            selector: "burn_per",
            key: "burn_per",
            width: 100
        },
        {
            name: "Burn($)",
            selector: "total_burn_dollar",
            key: "total_burn_dollar",
            width: 100
        },
        {
            name: "Burn(Token)",
            selector: "total_burn_token",
            key: "total_burn_token",
            width: "160px"
        },
        {
            name: "Recieved",
            selector: "total_receive",
            key: "total_receive",
            cell: (data) => <span>{data.total_receive.toFixed(2)}</span>,
            width: "100px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "250px"
        }

    ];
    const datapen = [
        ...withdrawdata
    ];
    const tableDatapen = {
        columns: columnspen,
        data: datapen
    };
    useEffect(() => {
        setNotice()
    }, [userData])
    return (
        <>
            <Header2 />
            {/* <AccountOverview/> */}
            <div className="blog-area bg-blue pd-top-100 pd-bottom-100 dashboard_box_image">
                <div className="container">
                    <div className="fact-area bg-cover bg-overlay-2 text-center">
                        <div className="container">
                            <div className="row mb-5">
                                <Adrbox />
                                <Blcbox />
                            </div>
                            <div class="row justify-content-center mb-4">
                                {withdrawdata.length ?
                                    <div class="col-lg-12">
                                        <div class="td-sidebar" >
                                            <div className="table-responsive text-nowrap tb">
                                                <div class="widget widget_add">
                                                    <h3 class="widget-title style-white text-left stakingliner" >Pending Withdrawal</h3>
                                                    <DataTableExtensions {...tableDatapen}>
                                                        <DataTable
                                                            columns={columnspen}
                                                            data={datapen}
                                                            noHeader
                                                            defaultSortField="id"
                                                            defaultSortAsc={true}
                                                            progressPending={loading}
                                                            highlightOnHover
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}
                            </div>
                            <TotalBox />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <div className="td-sidebar">
                                <Dashmenu currentAccount={account} />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row justify-content-center mb-4">
                                <div className="col-lg-12">
                                    <div className="td-sidebar" id="dtBasicExample">
                                        <div className="widget widget_catagory">
                                            <h3 className="widget-title style-white text-left stakingliner" >All Staking</h3>

                                            <div className="row" >
                                                {currentTableData.map(item => {
                                                    var todaydate1 = new Date();
                                                    var date = new Date(item.end_datetime);
                                                    var sdf = new SimpleDateFormat("yyyy-MM-dd'T'HH:mm:ssXXX");
                                                    sdf.format(date)
                                                    var todaydate = sdf.format(todaydate1)
                                                    var datadate = sdf.format(date)
                                                    // console.log("today",todaydate);
                                                    // console.log("data",datadate);
                                                    var istrue = false;
                                                    if (todaydate >= datadate) {
                                                        istrue = true;
                                                    } else {
                                                        // console.log("false");
                                                        istrue = false
                                                    }

                                                    // console.log(typeof item.datetime);
                                                    return (
                                                        <>
                                                            {item.status !== 2 ?
                                                                <div className="col-lg-6 col-md-12 col-12">
                                                                    <div className="single-plan-wrap">
                                                                        <div className="thumb">
                                                                            <img src="assets/img/icon/01.png" alt="img" className="lazy" />
                                                                        </div>
                                                                        <div className="details">
                                                                            {/* <h4>{item.t_type === 1 ? 'Restake' : 'Normal'}</h4> */}
                                                                            <h4 className='text-dark'>Total Staked </h4>
                                                                            <p>{item.total_token} {setting.ticker} ( ${item.total_investment} )</p><br />
                                                                            <h4 className='text-dark'>Total Reward</h4>
                                                                            <p>$ {item.total_reward} </p>
                                                                            <div className='pt-3'>
                                                                                <h4 className='text-dark'> Time Period</h4> <Clock deadline={item.end_datetime} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ""}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={Record.length}
                                            pageSize={PageSize}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* weekly */}
                            <div className="td-sidebar" >
                                <div className="table-responsive text-nowrap tb">
                                    <div className="widget widget_add">
                                        <h3 className="widget-title style-white text-left stakingliner" >Pending Referal Income</h3>

                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={true}
                                                pagination
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <div className="modal fade" id="exampleModalCenter1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="connectWalletPop1">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 style={{ color: "white" }}>{parse(titlehtml)}</h4>
                            </div>
                            <div className="modal-body text-center">
                                <div className="details"> <h5 style={{ color: "white" }}>{parse(popupcontenthtml)}</h5></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* claim */}

            <div className="modal fade" id="claimbutton" tabIndex="-1" role="dialog" aria-labelledby="claimbuttonModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="connectWalletPop1">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 style={{ color: "white" }}>Info</h4>
                            </div>
                            <div className="modal-body text-center">
                                <div className="details"> <h5 style={{ color: "white" }}>{parse(html)}</h5></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup for restake */}
            <button type="button" class="" data-toggle="modal" data-target="#restakeCenter" ref={restakePopup}>
            </button>
            <div className="modal fade" id="restakeCenter" tabIndex="-1" role="dialog" aria-labelledby="restakeCenterModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="connectWalletPop1">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h4 className="w-100 text-danger" >{ntfRestakeHeader}</h4>
                            </div>
                            <div className="modal-body text-center">
                                {/* <img src="grace-period.png" className='img-fluid w-25' /> */}
                                <div className="details"> <h5 style={{ color: "white" }}>{ntfRestake}</h5></div>
                                <p>Team</p>
                                <p>OXYO2</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Dashboard;