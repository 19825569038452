import React, { useContext, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { Oxyo2 } from '../Oxyo2';

const Header2 = (props) => {
    const { account, formatAddress, logout, userData } = useContext(Oxyo2)
    const history = useNavigate();

    // console.log("losspopo",props);
    const onlogout = () => {
        //  console.log("lopgout");
        logout();
        history("/");
    }
    useEffect(() => {
        if (localStorage.getItem("OawoISasSoi") !== 'QsaejkqwAy') {
            history("/");
        }
    }, [])

    return (
        <>

            <div className="navbar-area navbar-area-2">
                <nav className="navbar navbar-area navbar-expand-lg">
                    <div className="container nav-container">

                        <div className="responsive-mobile-menu">

                            <div className="dropdown d-block d-lg-none">
                                <button className="dropbtn btn btn-base">{account ? formatAddress(account) : account} <i
                                    className="fas fa-arrow-circle-right ms-2"></i></button>
                                <div className="dropdown-content mt-1">
                                    <button onClick={() => onlogout()} className="btn btn-base my-2" style={{ color: "#fff" }}>Logout</button>
                                </div>

                            </div>
                        </div>
                        <div className="logo">
                            <Link to="/"><img src="assets/img/logo.png" alt="img" /></Link>
                        </div>
                        <div className="nav-left-part">

                        </div>

                        <div className="collapse navbar-collapse" id="edumint_main_menu">
                            <ul className="navbar-nav menu-open text-center">

                                <li key={1} className="pull-right">
                                    <div className="dropdown">
                                        <button className="dropbtn btn btn-base">{account ? formatAddress(account) : account} <i
                                            className="fas fa-arrow-circle-right ms-2"></i></button>
                                        <div className="dropdown-content mt-1">
                                            <button onClick={() => onlogout()} className="btn btn-base my-2" style={{ color: "#fff" }}>Logout</button>
                                        </div>
                                    </div>
                                </li>
                                {/* <li key={2} className="pull-right"> <button className="btn btn-base me-2 ms-3" onClick={() => onClear()} >Clear data</button>
                                </li> */}
                                {userData.restaking ?
                                    <><li key={3} className="pull-right"> <a href="/#stakingsec"> <button className="btn btn-base me-2 ms-3" >RESTAKE NOW</button></a>
                                    </li></>
                                    : ''}
                            </ul>
                        </div>
                    </div>
                    <div className="nav-right-part nav-right-part-desktop">
                        {/* <!-- <a className="btn btn-base" href="contact.html">LEARN MORE <i className="fas fa-arrow-circle-right ms-2"></i>  </a> -->
                    <!-- <button className="dropbtn">Dropdown</button> --> */}

                    </div>
                </nav>
            </div>
        </>
    );
}

export default Header2;