import React, { useState, useEffect, useContext } from 'react';
// import AccountOverview from './AccountOverview';
// import AccountOverviewSubmission from './AccountOverviewSubmission';
import Footer from './Footer';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Adrbox from './DashboardAdrBox';
import Blcbox from './DashboardBlcBox';
import TotalBox from './DashboardTotalBox';
import {  toast } from 'react-toastify';
import OverviewStakings from './accoverviewStaking';
import AcOvReferralReward from './acoverviewReferralReward';
import OverviewWithdraw from './accoverviewWithdraw';
import OverviewTeam from './accoverviewTeam';
import OverviewStakingsReward from './accoverviewStakingReward';
import OverviewLeadershipReward from './accoverviewLeadershipReward';
import AccoverviewStatement from './accoverviewStatement';
import AccoverviewLegBusiness from './accoverviewLegBusiness';
import AccoverviewBuySell from './accoverviewBuySell';
import AccoverviewLeadershipHistory from './accoverviewLeadershipHistory';
import AccoverviewBonusReward from './accoverviewBonusReward';
import { Oxyo2 } from '../Oxyo2';

function AcOverview(props) {
    // console.log(props, "props");
    const { OverViewSet, setting } = useContext(Oxyo2);
    // const [Click, setClick] = useState(false);
    // const [Address, setAddress] = useState(location.state.address);
    const [Address, setAddress] = useState('');

    document.title = `Dashboard - ${process.env.REACT_APP_APP_NAME}`

    const [DashData, setDashData] = useState(1);
    const [Balance, setBalance] = useState(0);
    const [strongLag, setstrongLag] = useState(0);
    const [remainingLag, setremainingLag] = useState(0);
    const [showdata, setshowdata] = useState(false);

    const history = useNavigate();
    const logout = () => {
        // console.log("lopgout");
        props.onLogout();
        history("/");
    }

    const onGetOverview = async (e) => {
        var toastid = toast.info('Wait! we are Fetching Data ', {
            toastId: "customId",
            position: "top-right",
            // autoClose: 5000,
            // hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
        });
        OverViewSet(Address)
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getuser", address: Address.toLowerCase() })
            .then(res => {
                // console.log("resasda", res.data);
                if (res.data.length !== 0) {
                    // history('/account-overview', { state: { address: Address.toLowerCase() } })
                    setshowdata(true)
                    toast.update(toastid, {
                        render: 'data found',
                        type: "success",
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setshowdata(false)
                    toast.update(toastid, {
                        render: 'Oops! no record found😔 ',
                        type: "error",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
    }

    const onGetUser = async (e) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getuser", address: Address })
            .then(res => {
                // console.log("res.datares.datares.data", res.data);
                for (const iterator of res.data) {
                    // console.log("iterator.wallet_balance - iterator.total_withdrawal", iterator.wallet_balance, iterator.total_withdrawal);
                    setBalance(iterator.wallet_balance - iterator.total_withdrawal);
                    setremainingLag(iterator.remainingLag);
                    setstrongLag(iterator.strongLag);
                    // console.log("iterratotrer",res.data[0].reff_address);
                }
            })
    }
    const onGetDashD = async () => {
        if (Address) {
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getDashD", fromaddress: Address })
                .then(res => {
                    console.log("Get Dashboard details aasasass ", res.data);
                    setDashData(res.data);
                    // console.log("dasdasdasdasdad",res.data);
                })
        }
    }
    useEffect(() => {
        onGetDashD();
        onGetUser();
    }, [Address])

    return (
        <>
            <div className='h-100'>
                <div className="navbar-area navbar-area-2">
                    <nav className="navbar navbar-area navbar-expand-lg">
                        <div className="container nav-container">
                            <div className="responsive-mobile-menu">
                                <button className="menu toggle-btn d-block d-lg-none" data-target="#edumint_main_menu"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-left"></span>
                                    <span className="icon-right"></span>
                                </button>
                            </div>
                            <div className="logo">
                                <a href="/"><img src="assets/img/logo.png" alt="img" /></a>
                            </div>
                            <div className="nav-left-part">
                            </div>
                            <div className="collapse navbar-collapse" id="edumint_main_menu">
                                <ul className="navbar-nav menu-open text-center">
                                    <li className="current-menu-item"><a href="/">Home</a></li>
                                    <li className="current-menu-item"><a href="/about">Oxyo2 Token</a></li>
                                    <li className="current-menu-item"><a href="/stake">Stake Oxyo2</a></li>
                                    <li className="current-menu-item"><a href="/benefits">Benefits</a></li>
                                    <li className="current-menu-item"><a href="/roadmap">Roadmap</a></li>
                                </ul>
                            </div>
                            {/* <div className="dropdown ">
                                {!setting.connect_button_status ?
                                    <>
                                        {props.currentAccount !== '' ?
                                            <>
                                                {props.currentAccount !== '' ?

                                                    <button className="dropbtn btn btn-base" >{formated_address} <i
                                                        className="fas fa-arrow-circle-right ms-2"></i></button>
                                                    :
                                                    <button className="dropbtn btn btn-base" data-toggle="modal" data-target="#exampleModalCenter" >Connect <i className="fas fa-arrow-circle-right ms-2"></i></button>
                                                }

                                                <div className="dropdown-content mt-1">

                                                    <button className="btn btn-base my-2" style={{ color: "#fff" }}><a href="/dashboard">Dashboard</a></button>
                                                    <button onClick={() => logout()} className="btn btn-base my-2" style={{ color: "#fff" }}>Logout</button>
                                                </div></>
                                            :
                                            <div className="">
                                                {props.currentAccount !== '' ?
                                                    <button className="btn btn-base" onClick={() => props.onLoginHandler()} style={{ fontSize: "17px" }}>
                                                        {formated_address}<i className="fas fa-arrow-circle-right ms-2"></i></button>
                                                    :
                                                    <button className="btn btn-base" data-toggle="modal" data-target="#exampleModalCenter" style={{ fontSize: "17px" }}>
                                                        Connect<i className="fas fa-arrow-circle-right ms-2"></i></button>
                                                }
                                            </div>
                                        }
                                    </>
                                    :
                                    <button className="btn btn-base" data-toggle="modal" data-target="#connectbuttonadmin" style={{ fontSize: "17px" }}>
                                        Connect<i className="fas fa-arrow-circle-right ms-2"></i></button>
                                }
                            </div> */}
                        </div>
                    </nav>
                </div>
                {/* <Header2 logout={props.onLogout} currentAccount={props.currentAccount} /> */}

                <div className="blog-area bg-blue pd-bottom-100 pd-top-100 dashboard_box_image">
                    <div className="container" style={{ marginTop: "20px" }}>
                        <div className="call-to-action-area pd-top-115 pd-bottom-120 ">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10 align-self-center">
                                        <div className="section-title style-white mb-0 text-center">
                                            {/* <h5 className="sub-title">Check Profit/Loss</h5> */}
                                            <h2 className="title">Account Overview</h2>
                                            <div className="row">
                                                <div className="footer-area pt-4">
                                                    <div className="single-subscribe-inner">
                                                        <input type="text" placeholder="Search Address" style={{ backgroundColor: "rgb(82,82,82)", color: "#fff" }} onChange={(e) => setAddress(e.target.value.toLowerCase())} />
                                                        <a type="button" className="btn btn-base" onClick={() => onGetOverview()} style={{ marginTop: "0px" }}>Submit</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showdata ?
                            <div className="fact-area bg-cover bg-overlay-2 text-center">
                                <div className="container">
                                    {/* <AccountOverview setClick={setClick} setAddress={setAddress} Allmembers={Allmembers} /> */}
                                    <><div className="row mb-5">
                                        <Adrbox Address={Address} noacc={true} />
                                        <Blcbox Setting={setting} TokenRate={props.tokenrate} balance={Balance} nobal={true} position={DashData.position} />
                                    </div>
                                        <TotalBox balance={Balance} isB={true} currentAccount={Address} /></>
                                    <OverviewStakings currentAccount={Address} />
                                    <OverviewStakingsReward currentAccount={Address} />
                                    <AcOvReferralReward currentAccount={Address} />
                                    <AccoverviewBonusReward currentAccount={Address} />
                                    <OverviewLeadershipReward currentAccount={Address} />
                                    <AccoverviewLeadershipHistory currentAccount={Address} />

                                    <OverviewWithdraw currentAccount={Address} />
                                    <AccoverviewStatement currentAccount={Address} />
                                    <OverviewTeam currentAccount={Address} remainingLag={remainingLag} strongLag={strongLag} />
                                    <AccoverviewLegBusiness currentAccount={Address} />
                                    <AccoverviewBuySell currentAccount={Address} />
                                </div>
                            </div>
                            :
                            <div className="fact-area bg-cover bg-overlay-2 text-center w-100 h-100 " >
                                <div className="container ">
                                </div>
                            </div>}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}


export default AcOverview;