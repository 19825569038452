import React, { useState, useEffect } from 'react';
import "./styles.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import './pagination/style.css';
import axios from 'axios';
const AccoverviewLeadershipHistory = (props) => {
    // document.title = `AccoverviewLeadershipHistory - ${process.env.REACT_APP_APP_NAME}`
    // console.log("staking",props);
    const [staking, setstaking] = useState([]);
    const [loading, setLoading] = useState(false);

    const onGetAllRecord = async () => {
        if (props.currentAccount) {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "dashallstake", submethod: "adminUserLeaderRecord", address: props.currentAccount, page: 1, per_page: 1000 })
                .then(res => {
                    // console.log("res.data", res.data);
                    setstaking(res.data.data)
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        onGetAllRecord();
    }, [props.currentAccount])
    const columns = [
        {
            name: "#",
            selector: "sr",
            key: "sr",
            width: "110px"
        },
        {
            name: "Position",
            selector: "position",
            key: "position",
            width: "150px"
        },
        {
            name: "Reward Count",
            selector: "count_reward",
            key: "count_reward",
            width: "160px"
        },
        {
            name: "Strong Leg",
            selector: "strongLag",
            key: "strongLag",
            width: "120px"
        },
        {
            name: "Other Leg",
            selector: "remainingLag",
            key: "remainingLag",
            width: "120px"
        },
        {
            name: "Datetime",
            selector: "datetime",
            key: "datetime",
            width: "200px"
        }
    ];
    const data = [
        ...staking
    ];
    const tableData = {
        columns,
        data
    };
    return (
        <>

            <div class="col-lg-12">
                <div class="td-sidebar" >
                    <div className="table-responsive text-nowrap tb">
                        <div class="widget widget_add">
                            <h3 class="widget-title style-white text-left stakingliner" >Leadership History</h3>
                            <DataTableExtensions {...tableData} responsive={true}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    progressPending={loading}
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination

                                    highlightOnHover
                                    dense
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </>
    );
}

export default AccoverviewLeadershipHistory;