import React from 'react';

function Process() {
    return (
        <>

            <div className="process-area border-bottom bg-blue text-center pd-bottom-50 pd-top-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 align-self-center">
                            <div className="section-title style-white text-center">
                                <h5 className="sub-title">All Incomes</h5>
                                <h2 className="title">Incomes</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-3 col-sm-6">
                            <div className="single-process-inner">
                                <div className="default-card-inner" />
                                <div className="thumb">
                                    <img src="assets/img/home-2/process/staking-profit.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Staking Profit</h4>
                                    <p>Get 225% Staking bonus in 450days.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-process-inner">
                                <div className="default-card-inner" />
                                <div className="thumb">
                                    <img src="assets/img/home-2/process/referral-bonus.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Referral Bonus</h4>
                                    <p className='text-justify'>Refer Oxyo2 to all your contacts,Build Oxyo2 community strong & get 32% <p> referral bonus.</p></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-process-inner">
                                <div className="default-card-inner" />
                                <div className="thumb">
                                    <img src="assets/img/home-2/process/leadership-fund.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Leadership Fund</h4>
                                    <p>As you grow your team business strength, you grow your leadership bonus for next 52 week.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-process-inner">
                                <div className="default-card-inner" />
                                <div className="thumb">
                                    <img src="assets/img/home-2/process/re-activation-bonus.png" alt="img" />
                                </div>
                                <div className="details">
                                    <h4>Reactivation bonus</h4>
                                    <p>Anyone reached 4x earning of their staking amount, every reactivation will give you bonus again & again.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Process;