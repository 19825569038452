import React, { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oxyo2 } from '../Oxyo2';
var dateTime = require('node-datetime');

const LearnMoreFeatures = (props) => {
    const { account, library, setting, connectMetamask, userData, getUserData, Balance, toastError, toastSuccess, toastInfo, toastDismiss, RATE, LIVERATE,userRegistered } = useContext(Oxyo2);

    const { referrral } = useParams('')
    // console.log("im from form learnmore", setting.newstake_status);
    const [Response, setResponse] = useState("nores");
    const [RefAdd, setRefAdd] = useState(referrral);
    const [check, setcheck] = useState(false);
    const [checkMsg, setcheckMsg] = useState(false);
    const [rate, setrate] = useState(0);
    const [css, setcss] = useState('0.5');
    const [bln, setbln] = useState(0);
    const [activetr, setactivetr] = useState(0);
    //cntxt
    const [hidesbtn, sethidesbtn] = useState(false);
    const [Range, setRange] = useState(20);
    const [totalstake, setTotalstake] = useState(Range / LIVERATE);
    const [stakingreward, setStakingreward] = useState(0);
    const [dailyreward, setDailyreward] = useState(0.50);
    const [minInv, setminInv] = useState();
    const [TotalStakingRes, setTotalStakingRes] = useState();
    const [rtn, setrtn] = useState("");


    // console.log(`${process.env.REACT_APP_BACKEND_LINK}update`);
    // console.log("log", props.userStatus)
    const getBl = async () => {
        if (!account || !setting || !library) {
            return
        }

        // var blnva = await library.eth.getBalance(account);
        const token = new library.eth.Contract(JSON.parse(setting.token_abi), setting.token_address);
        const blof = await token.methods.balanceOf(account).call();
        const accBalanceEth = library.utils.fromWei(blof, "ether");
        // console.log("Testse",accBalanceEth);
        setbln(Number(accBalanceEth))

    }

    const checkactivetr = async (e) => {
        if (!account) {
            return
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getactivetr", address: account })
            .then(res => {
                // console.log("29 2929292959295292295292295", res.data);
                // const data = res.data;
                console.log("res", res.data.data);
                if (res.data.error) {
                    return
                }
                setactivetr(res.data.data)
            })
    }
    const onRefAddress = async (e) => {
        var sdasdj = await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "update", address: account, reff_address: RefAdd.toLowerCase() })
            .then(res => {
                // console.log("sdasd", res.data);
                const data = res.data;
                var das = typeof data
                if (das === 'object') {
                    setResponse(data[0].name);
                    return data[0].name
                    // setEmail("");
                    // setName("");
                } else if (das === 'string') {
                    setResponse(data);
                    return data
                }
                // console.log("das",typeof data,!data,data);
            })
        return sdasdj;
    }
    const totaltoken = (e) => {
        // console.log("RATE", RATE);
        document.getElementById('rangeInput').value = e.target.value
        document.getElementById('rangeinputbox').value = e.target.value
        setRange(e.target.value);
        // const total = (e.target.value / );
        setTotalstake(e.target.value / LIVERATE)
        //console.log("REward", totaltoken)
        setStakingreward((e.target.value) * dailyreward / 100)
        // console.log("totalstake",(e.target.value / RATE) * dailyreward / 100);
    }
    const handleOnChange = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setcheck(true);
        } else {
            setcheck(false);
        }
        // console.log("chcekckc ", checked);
    };
    const inputclick = () => {
        // console.log("account", account);
        if (!account) {
            toastError('Connect first!')
        }
    }
    const RefSett = async (e) => {
        // await onRefAddress()
        var onrefst = await onRefAddress()
        // console.log(!Response, "refsett", Response,onrefst);
        if (onrefst === 'Invalid referral address' || onrefst === 'nores') {
            // if (Response === 'Invalid referral address' || Response === 'nores') {
            toast.dismiss()

            setTimeout(() => {
                if (Response === 'Invalid referral address' || Response === 'nores') {
                    toastError('Referral is not valid!')

                    setcss('0.5');
                }
            }, 2000);
        } else {

            toast.dismiss();
            var onref = await onRefAddress()
            setcss('1');
        }
        setTimeout(() => {
            // console.log("Timeout");
            getUserData();
        }, 2000)

        // console.log("om getuser");

    }
    const HandleonTransaction = async (e) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "getactivetr", address: account })
                .then(res => {
                })
        } catch (error) {
            toastError('Try after sometime!')
            return
        }

        if (check) {
            onTransaction()
            // onRefAddress()
            setcheckMsg(false);

        }
        else {
            setcheckMsg("Agree the terms & condition ");
        }
    }

    const onTransaction = async () => {
        const checkt = [];
        
        if (Range > 10000 || Range < 20) {
            toastDismiss();
            toastError('Stake between $20 and $10000!')
            sethidesbtn(false)
            return
        }
        if (Number(Balance) < totalstake) {
            toastDismiss();
            toastError('Low Balance!')
            sethidesbtn(false)
            return
        }
        if(userData.blocked === true){ 
            toastError('Your account is blocked') 
            return 
        }
        sethidesbtn(true)
        toastInfo('Transaction Initialized!')
        var a = new Date()
        a.setDate(a.getDate() + Number(process.env.REACT_APP_END_DATE_DAY))
        var setTime = `${a.getFullYear()}-${(a.getMonth() + 1).toString().padStart(2, 0)}-${(a.getDate()).toString().padStart(2, 0)} ${(a.getHours()).toString().padStart(2, 0)}:${(a.getMinutes()).toString().padStart(2, 0)}:${(a.getSeconds()).toString().padStart(2, 0)}`

        var ab = new Date()
        var timestamps = ab.setDate(ab.getDate())
        var dt = dateTime.create();

        var date_formatted = dt.format('Y-m-d H:M:S');

        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "penaddtransaction", fromaddress: account, toaddress: "0x1deEfB477F21025e05eBb4dE9261391E719d8831", total_investment: Range, token_rate: LIVERATE, total_token: totalstake.toFixed(2), datetime: date_formatted, datetime_unix: timestamps, end_datetime: setTime,  block_timestamp: "0", t_type: userData.restaking ? 1 : 0, status: 0 })
            .then(res => {
                //console.log("log in pen",res.data);
            })
        async function main() {
            var a = new Date()
            var timestamp450 = a.setDate(a.getDate() + Number(process.env.REACT_APP_END_DATE_DAY))

            var contract = new library.eth.Contract(JSON.parse(setting.abi), setting.contract_address);
            // console.log("totalstake", totalstake);
            await contract.methods.stake(account, library.utils.toWei(`${totalstake}`, "ether"), timestamp450).send({ from: account },
                async function (err, transactionId) {
                    if (err) {
                        toastDismiss();
                        sethidesbtn(false)
                        toastError('Transaction declined!')
                        return
                    }
                  

                }).then(async res => {
                    setTotalStakingRes(1)
                    setrtn(res.transactionHash);

                    var a = new Date()
                    a.setDate(a.getDate() + Number(process.env.REACT_APP_END_DATE_DAY))
                    var setTime = `${a.getFullYear()}-${(a.getMonth() + 1).toString().padStart(2, 0)}-${(a.getDate()).toString().padStart(2, 0)} ${(a.getHours()).toString().padStart(2, 0)}:${(a.getMinutes()).toString().padStart(2, 0)}:${(a.getSeconds()).toString().padStart(2, 0)}`

                    var ab = new Date()
                    var timestamps = ab.setDate(ab.getDate())
                    var dt = dateTime.create();

                    var date_formatted = dt.format('Y-m-d H:M:S');

                    await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "addtransaction", hash: res.transactionHash, fromaddress: account, toaddress: "0x1deEfB477F21025e05eBb4dE9261391E719d8831", total_investment: Range, token_rate: LIVERATE, total_token: totalstake.toFixed(2), datetime: date_formatted, datetime_unix: timestamps, end_datetime: setTime, end_datetime_unix: timestamp450, block_timestamp: "0", t_type: userData.restaking ? 1 : 0, status: 0 })
                        .then(res => {
                            // console.log("this is response databasre", res);
                            getUserData();
                            toastDismiss();
                            sethidesbtn(false)
                            toastSuccess('Transaction Successfull!')

                        })
                    toastDismiss();
                });
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "checktotal", fromaddress: account, value: Range })
            .then(res => {
                const data = res.data;
                checkt.push(data)
                setminInv(checkt[0][0].min);
                // setuserTotalStaking(checkt[0][0].f_total);
                (async () => {
                    if (checkt[0][0].status === 1) {
                        //call the function main
                        await main()
                    } else {

                        sethidesbtn(false)
                        toastDismiss();
                        if (checkt[0][0].min) {
                            toastError(`Stake minimum $ ${checkt[0][0].min}`)
                        } else {
                            toastError(`You can stack only $ ${10000 - checkt[0][0].f_total}`)
                        }
                    }
                })();
            })
    };
    const html = setting.newstake_message ? setting.newstake_message : '<span></span>';
    useEffect(() => {
        getBl()
    }, [account, setting, library])
    useEffect(() => {
        if (!userData.reff_address) {
            setcss('0.5');
        } else if (userData) {
            setcss('1')
        }
        if (!account) {
            setcss('0.5')
        }
    }, [account, userData])

    useEffect(() => {
        checkactivetr()
    }, [account])
    useEffect(() => {
        setTotalstake(Range / LIVERATE)
    }, [LIVERATE])
    return (
        <>
            <section className="pages_content" id="staking">
                <div className="featured-area text-center pd-top-95 pd-bottom-70 gradient-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title style-white text-center">
                                    <h5 className="sub-title">Oxyo2 Staking</h5>
                                    <h2 className="title">Stake Now</h2>
                                    <p className="content">Stake oxyo2 and enjoy Staking Profit, Referral Bonus and Leadership fund incomes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="match-table-inner">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="section-title style-white mb-0 text-center ">
                                        {setting.newstake_status ?
                                            parse(html)
                                            :
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="single-input-inner">
                                                        {
                                                            !userData.reff_address ?
                                                                <div className="form-group" style={{ textAlign: "left" }}>
                                                                    <span className="input-group-text">Referral Address ( Required )</span>
                                                                    <input type="text" id="add" className="form-control referal_address  input-lg" placeholder="Enter referal address" autocomplete="off" name="address" value={RefAdd} onKeyUp={(e) => onRefAddress(e.target.value)} onChange={(e) => setRefAdd(e.target.value)} />
                                                                    {/* <label style={{ color: "red" }}>{Response}</label> */}
                                                                    <br />
                                                                    <div className='text-center'>
                                                                        {account ?
                                                                            <button type="button" className="btn btn-base mt-3" onClick={(e) => RefSett()} style={{ padding: "0px 33px" }}>Register</button>
                                                                            :
                                                                            <button type="button" onClick={() => connectMetamask()} className="btn btn-base mt-3" style={{ padding: "0px 63px" }}>Connect</button>}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="form-group" style={{ textAlign: "left" }}>
                                                                    <span className="input-group-text">Referral Address </span>
                                                                    <input type="text" readOnly={true} value={userData ? userData.reff_address ? userData.reff_address : '' : ''} onClick={(e) => inputclick()} />
                                                                    <br />
                                                                    <div className='text-center'>
                                                                        {account ?
                                                                            ''
                                                                            :
                                                                            <button type="button" onClick={() => connectMetamask()} className="btn btn-base mt-3" style={{ padding: "0px 63px" }}>Connect</button>}
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ opacity: css }}>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12">
                                                            <div className="single-input-inner">
                                                                <span className="input-group-text" style={{ fontSize: "30px", fontWeight: 'bold' }}> {userData ? userData.restaking ? 'Restaking is Required to activate your account' : 'Select amount to Stake' : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 d-flex">
                                                            <div className='single-input-inner w-100'>
                                                                <div className='form-group'>
                                                                    <input type="number" defaultValue={20} id='rangeinputbox' onChange={(e) => totaltoken(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input id="rangeInput" defaultValue={20} className="rangeselector" onChange={(e) => totaltoken(e)} type="range" min="20" max="2500" />
                                                    <label htmlFor="" className="slider_info">{minInv ? <span className='text-white'>Stake minimum ${minInv} to active your account</span> : ''}</label>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-6 col-lg-3 col-sm-6">
                                                            <div className="single-feature-inner default-card">
                                                                <div className="default-card-inner"></div>
                                                                <div className="details">
                                                                    <h4 style={{ color: "white" }}>Rate</h4>
                                                                    <h5 style={{ color: "white", fontWeight: "400" }}>$ {LIVERATE ? LIVERATE : rate}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 col-sm-6">
                                                            <div className="single-feature-inner default-card">
                                                                <div className="default-card-inner"></div>
                                                                <div className="details">
                                                                    <h4 style={{ color: "white" }}>Balance </h4>
                                                                    <h5 style={{ color: "white" }}>{<span>{bln > 0 ? bln.toFixed(4) : '0'} {setting.ticker}</span>}</h5>
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 col-sm-6">
                                                            <div className="single-feature-inner default-card">
                                                                <div className="default-card-inner"></div>
                                                                <div className="details">
                                                                    <h4 style={{ color: "white" }}>Total Stake </h4>
                                                                    <h5 style={{ color: "white", fontWeight: "400" }}>{totalstake ? totalstake.toFixed(2) : totalstake} {setting.ticker}</h5><br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 col-sm-6">
                                                            <div className="single-feature-inner default-card">
                                                                <div className="default-card-inner"></div>
                                                                <div className="details">
                                                                    <h4 style={{ color: "white" }}>Staking Reward</h4>
                                                                    <h6 style={{ color: "white", fontWeight: "400" }}>$ {stakingreward ? stakingreward.toFixed(2) : '0'}/ Per Day<br />({dailyreward ? dailyreward.toFixed(2) : dailyreward}% Daily for 450 Days)</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onClick={(e) => { handleOnChange(e); }} />
                                                        <label className="form-check-label check_info_content text-white" htmlFor="inlineCheckbox1" style={{ fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;I agree to all terms & conditions.* </label><br />
                                                        <label className='text-danger'>{checkMsg ? checkMsg : ''}</label>
                                                    </div>
                                                </div>

                                                {rtn ?
                                                    <div className="col-12">
                                                        {TotalStakingRes == 0 ? '' :
                                                            <div className="single-feature-inner default-card">
                                                                <div className="default-card-inner"></div>
                                                                <div className="details">
                                                                    {/* <h4 >{props.isSuccess === 1 ? "Transaction successfully" : ""}
                                                                        {props.isSuccess === 2 ? "Please wait while your transaction is complete" : ""}</h4> */}
                                                                    <h5 className="title">
                                                                        {TotalStakingRes !== 0 ?
                                                                            <>
                                                                                {rtn ? "Transaction Hash: " : ''}
                                                                            </>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </h5>
                                                                    <h5 className="title">
                                                                        {TotalStakingRes !== 0 ?
                                                                            <>
                                                                                {rtn ? <a target="_blank" rel="noopener noreferrer" href={`https://bscscan.com/tx/${rtn}`}> {rtn}</a> : ''}
                                                                            </>
                                                                            : ""}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* <h5 className="title">{TotalStakingRes === 0 ? `You can stake only:${500 - props.userTotalStaking} ` : ''}</h5> */}
                                                    </div>
                                                    : ''}
                                                <div className="col-12 " style={{ opacity: css }}>
                                                    {activetr === 0 ?
                                                        <>

                                                            {account ?
                                                                <>
                                                                    {!hidesbtn ?
                                                                        <>
                                                                            {css === '1' ?
                                                                                <button type="button" onClick={() => HandleonTransaction()} className="btn btn-base mt-3" style={{ padding: "0px 63px" }}>Stake  <i className="fas fa-arrow-circle-right ms-2"></i></button>
                                                                                : ''}

                                                                            {rtn && TotalStakingRes !== 0 ?
                                                                                <a href="/dashboard"> <button type="button" className="btn btn-base mt-3" style={{ padding: "0px 33px" }}> Dashboard <i className="fas fa-arrow-circle-right ms-2"></i></button></a>
                                                                                : ''}
                                                                        </> : ''}
                                                                </>
                                                                :
                                                                <button type="button" onClick={() => connectMetamask()} className="btn btn-base mt-3" style={{ padding: "0px 63px" }}>Connect</button>
                                                            }

                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LearnMoreFeatures;
